@import "../../includes/settings.scss";

.form-container {
    flex: 1 1 100%;
    font-family: Helvetica;

    .contact-form {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        justify-content: center;
        padding: 2rem 10%;
        background-color: $select-background;
        border: 1px solid $table-border;
        box-sizing: border-box;

        .form-row {
            margin: 0.5rem 0;
            
            .field-group {
                flex: 1 1 100%;
                margin: 0;

                label {
                    font-size: 1rem;
                    line-height: 150%;
                    margin: 10px 0 5px 0;
                }

                .form-input-container {
                    width: 100%;

                    .uploaded_images {
                        margin-top: 0;
                    }

                    .form-error {
                        text-align: center;
                    }

                    textArea,
                    input {
                        padding-left: 1rem;
                        background-color: $select-background;
                        border: 0.6px solid $form-border;
                        box-sizing: border-box;
                        box-shadow: 0px 12px 20px rgba(64, 45, 27, 0.1);
                    }
                    textArea {
                        height: 10rem;
                    }
                }
            }
        }

        .form-container-button {
            flex: 1 1 100%;
            margin: 2rem 0;

            button {
                flex: 0 1 50%;
            }
            p {
                font-size: 18px;
                margin-bottom: 1.5rem;
            }
            a {
                cursor: pointer;
                color: $text-secondary;
            }
        }
        .form-success {
            overflow: hidden;
            width: 100%;
            flex-direction: column;

            h1 {
                font-size: 30px;
                line-height: 23px;
                text-align: center;
                margin: 2rem 1rem;
                color: $text-heading;
                font-weight: 600;
                letter-spacing: 1px;
            }
            h2 {
                font-size: 28px;
                line-height: 31px;
                text-align: center;
                margin: 2rem 1rem;
                color: $text-heading;
                font-weight: 550;
                letter-spacing: 1px;
            }
            p {
                font-size: 24px;
                letter-spacing: 0.42px;
                line-height: 24px;
                text-align: center;
                margin: 2rem;
                color: $text-primary;
            }
            .logo {
                margin: 20px;
                height: 70%;
                width: 70%;

                position: relative;
                right: unset;
                top: unset;
            }
        }
    }
    .form-container-head {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        align-content: flex-start;

        ul {
            font-size: 18px;
            line-height: 150%;
            display: flex;
            align-items: baseline;

            li {
                margin-right: 2rem;
            }
            .square {
                list-style-type: square;
                font-size: 25px;
                strong {
                    color: $text-secondary;
                    font-size: 18px;
                }
            }
        }

        h1 {
            margin: 15px 0;
            font-weight: bold;
            font-size: 36px;
            line-height: 150%;
            color: $text-heading;
            line-height: 54px;
        }
    }
}

// Phone
@media only screen and (min-device-width: $phone) and (max-device-width: $tablet) {
    .form-container {
        .contact-form {
            .form-container-button {
                margin: 1rem 0;
                button {
                    flex: 0 1 70%;
                }
                p {
                    font-size: 1rem;
                    text-align: center;
                }
            }
        }
    }
}
