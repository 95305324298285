@import "../includes/settings.scss";

.blogList-container {
    .header-container {
        .container-header {
            background-position-x: -400px;
        }
    }
}
// Phone
@media only screen and (min-device-width: $phone) and (max-device-width: $desktop) {
.blogList-container {
    .header-container {
        .container-header {
            
            background-position-x: unset;
        }
    }
}
}
