@import "../../App.scss";
.header-container {
    flex: 1 1 100%;
    height: 90vh;
    align-content: flex-start;

    .container-header {
        background-repeat: no-repeat;
        background-size: cover;
        align-items: flex-start;
        align-content: flex-start;
        padding: 0 7%;
        flex-direction: column;
        font-family: Helvetica;
        height: 80%;
        flex: 1 1 100%;
        background-color: rgba(0, 0, 0, 0.5); // Tint color
        background-blend-mode: multiply;

        .container {
            flex-direction: column;
            align-items: flex-start;
            margin-top: 7%;
        }

        .text-large {
            color: $text-secondary;
            font-size: 86px;
            line-height: 86px;
            font-weight: 800;
        }
        .text-medium {
            margin: 1rem 0;
            color: $text-light;
            font-style: normal;
            font-weight: bold;
            font-size: 36px;
            line-height: 110%;
        }

        .form-container {
            flex: unset;
        }
        .search-container {
            margin: 1rem 0;
            padding: 50px 70px;
            background-color: $text-light;
            opacity: 0.9;

            input,
            select {
                width: 270px;
                margin: 0 30px;
                padding: 20px 10px;
            }
            button {
                width: 270px;
                margin: 0 30px;
            }
        }
        .search-container.hidden {
            display: none;
        }
        .job-search {
            margin-top: 7rem !important;
            padding: 50px 10px;
            justify-content: space-between;

            .options-container {
                width: 100%;
                justify-content: space-between;
                margin-top: 1rem;
                padding: 0 20px;

                .search-filter {
                    color: $text-secondary;
                    font-family: Helvetica;
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 110%;
                    letter-spacing: 0.1em;
                }
                .search-return {
                    font-family: Helvetica;
                    color: $text-primary;
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 110%;
                    letter-spacing: 0.1em;
                }
            }

            input,
            select {
                width: 270px;
                padding: 15px 10px;
                margin: 0 15px;
            }
            button {
                width: 270px;
                margin: 0 15px;
                padding: 15px 70px;
            }
        }
    }
    .container-header.centered {
        align-items: center;
        align-content: center;

        .container {
            align-items: center;
        }
    }
}
// Phone
@media only screen and (min-device-width: $phone) and (max-device-width: $desktop) {
    .jobBoard-container,
    .jobDetails-container,
    .job-container,
    .contact-container,
    .post-container,
    .apply-container,
    .blogList-container,
    .about-container,
    .Legal,
    .home-container {
        .header-container {
            height: unset;

            .container-header {
                background-position-x: unset;
                padding: 10% 0;
                background-position-x: 64%;
                align-items: flex-start;
                flex-basis: 100%;
                align-items: center;
                flex-direction: column;
                text-align: center;
                
                .container {
                    margin-top: unset;
                    align-items: center;
                }

                .text-large {
                    width: 80%;
                    color: $text-secondary;
                    font-size: 46px;
                    line-height: 56px;
                    font-weight: 800;
                }
                .text-medium {
                    margin: 1rem 0;
                    color: $text-light;
                    font-family: Helvetica;
                    font-weight: bold;
                    font-size: 20px;
                    line-height: 100%;
                }
                .form-container {
                    padding: 5%;
                }
                .search-container {
                    margin: 20px 0;
                    padding: 10px 0;
                    width: 100%;

                    input,
                    select {
                        margin: 10px 15px;
                        padding: 10px 35px;
                    }
                    button {
                        margin: 10px 0;
                        padding: 10px 40px;
                    }
                }

                .job-search {
                    margin-top: unset !important;
                    padding: 20px 0;
                    justify-content: center;

                    .options-container {
                        flex-direction: column;
                        justify-content: center;

                        .search-return {
                            margin: 10px 0;
                            font-size: 16px;
                            line-height: 100%;
                        }
                    }

                    input,
                    select {
                        padding: 15px 10px;
                        margin: 10px 15px;
                    }
                    button {
                        margin: 0 15px;
                        padding: 15px 70px;
                    }
                }
            }
            .container-header.centered {
                text-align: center;
                padding: 4rem 0;
                height: 20vh;
                .text-medium {
                    font-size: 36px;
                    margin: 0 2rem;
                }
            }
        }
    }
}
// XS Phone
@media only screen and (max-device-width: 320px) {
    .jobBoard-container,
    .jobDetails-container,
    .job-container,
    .contact-container,
    .post-container,
    .apply-container,
    .blogList-container,
    .home-container {
        .container-header {
            .form-container {
                padding: 5% 0;
            }
        }
    }
}

//Tablet
@media only screen and (min-device-width: $tablet) and (max-device-width: $desktop) {
    .jobBoard-container,
    .jobDetails-container,
    .job-container,
    .contact-container,
    .post-container,
    .apply-container,
    .about-container,
    .blogList-container,
    .home-container {
        .header-container {
            .container-header {
                align-items: baseline;
                padding: 10%;
                .text-large {
                    width: unset;
                    font-size: 46px;
                }
                .text-medium {
                    font-size: 26px;
                }
                .form-container {
                    padding: 0;
                    .search-form {
                        padding: 5px 0;
                    }
                }
                .search-container {
                    justify-content: space-evenly;
                    padding: 20px 10px;
                    width: 75%;

                    input,
                    select {
                        width: 170px;
                        margin: 10px 15px;
                        padding: 10px 35px;
                    }
                    button {
                        width: 170px;
                        margin: 10px 0;
                        padding: 10px 40px;
                    }
                }

                .job-search {
                    select {
                        width: 160px;
                        padding: 15px 10px;
                        margin: 10px 15px;
                    }
                    button {
                        margin: 20px 15px !important;
                        width: 250px;
                        padding: 15px 10px;
                    }
                    .options-container {
                        flex-direction: row;
                        justify-content: space-between;
                    }
                }
            }
            .centered {
                height: 36vh;
            }
        }
    }
}

//Tablet
@media only screen and (min-device-width: $tablet) and (max-device-width: $desktop) and (orientation: landscape) {
    .jobBoard-container,
    .jobDetails-container,
    .job-container,
    .Legal,
    .contact-container,
    .post-container,
    .apply-container,
    .about-container,
    .blogList-container,
    .home-container {
        .header-container {
            .container-header {
                padding: 10% 3%;
            }
            .centered {
                background-position-x: 0;
                height: 45vh;
            }
        }
    }
}

// Large
@media only screen and (min-device-width: 2500px) {
    .jobBoard-container,
    .jobDetails-container,
    .job-container,
    .Legal,
    .contact-container,
    .post-container,
    .apply-container,
    .about-container,
    .blogList-container,
    .home-container {
        .header-container {
            .container-header {
                .container {
                    flex-direction: column;
                    align-items: flex-start;
                    margin-left: 15%;
                }
            }
            .container-header.centered {
                .container {
                    margin-left: 0;
                }
            }
        }
    }
}

//4K
@media only screen and (min-device-width: 4000px) {
    .jobBoard-container,
    .jobDetails-container,
    .job-container,
    .Legal,
    .contact-container,
    .post-container,
    .apply-container,
    .about-container,
    .blogList-container,
    .home-container {
        .header-container {
            .container-header {
                .container {
                    margin-left: 28%;
                }
            }
        }
    }
}
