@import "./includes/settings.scss";
@import "./includes/mixins.scss";

// Fonts
@font-face {
    font-family: "Raleway";
    src: url("../assets/fonts/Raleway-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

:root {
    font-size: 16px;
    font-family: "Montserrat";
    --text-primary: #b6b6b6;
    --text-secondary: #ececec;
    --bg-primary: #23232e;
    --bg-secondary: #141418;
    --transition-speed: 600ms;
}

body {
    color: $text-dark;
    background-color: white;
    margin: 0;
    padding: 0;

    div {
        display: flex;
        position: relative;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
    }

    li,
    ul,
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        list-style-type: none;
        padding: unset;
        margin: unset;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    button {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
    }

    main {
        margin-left: 5rem;
        padding: 1rem;
        flex: 1 1 100%;
    }
}

body::-webkit-scrollbar {
    width: 0.25rem;
}

body::-webkit-scrollbar-track {
    background: #1e1e24;
}

body::-webkit-scrollbar-thumb {
    background: $primary;
}

// Theme Options
.dark {
    --text-primary: #b6b6b6;
    --text-secondary: #ececec;
    --bg-primary: #23232e;
    --bg-secondary: #141418;
}

.light {
    --text-primary: #1f1f1f;
    --text-secondary: #000000;
    --bg-primary: #ffffff;
    --bg-secondary: #e4e4e4;
}

.solar {
    --text-primary: #576e75;
    --text-secondary: #35535c;
    --bg-primary: #fdf6e3;
    --bg-secondary: #f5e5b8;
}

.theme-icon {
    display: none;
}

.dark #darkIcon {
    display: block;
}

.light #lightIcon {
    display: block;
}

.solar #solarIcon {
    display: block;
}

.text-muted {
    color: #6c757d !important;
}

.column-container {
    flex-direction: column;
    flex: 1 1 100%;
}
.error-message {
    flex: 1 1 100%;
    flex-direction: column;
    margin: 2rem 0;
    align-items: center;
    text-align: center;
    h1 {
        color: $text-heading;
        font-weight: bold;
        padding: 0 1rem;
        font-size: 2em;
        line-height: 110%;
        text-align: right;
        letter-spacing: 0.1em;
    }
    p {
        margin: 2rem 0;
        font-size: 18px;
        padding: 0 1rem;
        line-height: 140%;
        letter-spacing: 0.1em;
    }
}
//  Form Styles
form {
    width: 100%;

    .form-group {
        display: flex;
        flex-flow: row wrap;

        label {
            margin: 5px 10px 5px 0;
            flex: 0 0 100%;
        }

        input {
            padding: 0.375rem 0.75rem;
            flex: 0 0 100%;
            box-sizing: border-box;
            border: 2px solid #ededed;
            border-radius: 5px;
            margin: 5px 0px;
            border-radius: 0.25rem;
            transition: border-color 0.15s ease-in-out,
                box-shadow 0.15s ease-in-out;
        }

        select {
            padding: 0.375rem 0.75rem;
            flex: 0 0 100%;
            box-sizing: border-box;
            border: 2px solid #ededed;
            border-radius: 5px;
            margin: 5px 0px;
            border-radius: 0.25rem;
            transition: border-color 0.15s ease-in-out,
                box-shadow 0.15s ease-in-out;
        }

        button[type="submit"] {
            flex: 0 0 100%;
            margin: 1rem 0;
        }
    }
}

// Large
@media only screen and (min-device-width: 2100px) {
    .clamp {
        max-width: 1920px;
    }
}

.border-style {
    position: absolute;
    top: -10%;
    left: 0;
    width: 100px;
    border-bottom: 6px solid $text-secondary;
    border-radius: 4px;
}
// Button Styles
.btn-primary {
    background-color: $primary;
    font-family: "Montserrat";
    font-size: 1.2rem;
    font-weight: 600;
    border-color: $primary;
    border-radius: 5px;
    color: $text-light;
    padding: 0.5rem 1rem;
    transition: background-color 0.5s ease-in-out, border-color 0.5s ease-in-out;

    &:hover {
        background-color: $secondary;
        border-color: $secondary;
    }

    &:active {
        text-decoration: underline;
    }

    &:focus {
        text-decoration: underline;
        box-shadow: 0 0 0 0.2rem rgba($secondary, 0.5);
    }
}

.btn-share {
    font-family: "Montserrat";
    font-size: 1.2rem;
    font-weight: 600;
    background: $select-background;
    color: $select-text;
    padding: 17px 25px;
    transition: background-color 0.5s ease-in-out, border-color 0.5s ease-in-out;
    border: 1px solid $button-text;
    box-sizing: border-box;
    box-shadow: 0px 8px 20px rgba(64, 45, 27, 0.1);

    display: flex;
    align-items: center;

    &:hover {
        border-color: $text-secondary;
    }

    &:active {
        text-decoration: underline;
    }

    &:focus {
        text-decoration: underline;
        box-shadow: 0 0 0 0.2rem rgba($secondary, 0.5);
    }
}

.btn-link {
    background-color: transparent;
    font-family: "Montserrat";
    border: unset;
    color: $text-dark;
    font-weight: 700;
    text-decoration: underline;
}
