@import "../../App.scss";
.services-container {
    flex: 1 1 100%;
    margin-top: 50px;

    .heading-wrapper {
        width: 100%;
        margin: 1rem 0 5rem 0;
        background-color: $button-main;
        min-height: 200px;
        justify-content: flex-end;
        align-content: baseline;
        padding-top: 100px;
        padding-bottom: 150px;

        .heading-container {
            position: absolute;
            top: 15%;
            left: 10%;
            h1 {
                z-index: 100;
                color: white;
                font-weight: bold;
                font-size: 48px;
                line-height: 110%;
                text-align: left;
                letter-spacing: 0.1em;
            }

            img {
                position: absolute;
                right: 15%;
                z-index: -10;
                bottom: 45%;
            }
        }

        .heading-text {
            width: 65%;
            margin-right: 5rem;
            justify-content: flex-start;

            p {
                color: white;
                font-size: 18px;
                line-height: 140%;
                letter-spacing: 0.1em;
            }
            .container {
                justify-content: flex-start;
                margin: 1rem 0;
                h3 {
                    margin: 0.5rem 0;
                    font-size: 20px;
                    color: white;
                }
            }
        }
    }
    .our-services {
        width: 80%;
        transform: translateY(-200px);
        background-color: white;
        border: 1px solid $table-border;
        z-index: 100;
        padding: 4rem 0;

        .col {
            flex: 1 1 100%;

            .row {
                flex: 1 1 100%;
                align-items: flex-start;
                justify-content: space-evenly;

                .imgWrap {
                    width: 100%;

                    img {
                        width: 100%;
                    }
                }
                .service-card {
                    flex-basis: 25%;
                    flex-direction: column;
                    margin: 0 20px;

                    .card-label {
                        position: absolute;
                        top: 25px;
                        left: -40px;

                        color: white;
                        background-color: $button-main;
                        padding: 8px 25px;
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 110%;
                        letter-spacing: 0.1em;
                    }
                    p {
                        margin: 1rem 0;
                        font-family: Helvetica;
                        font-size: 14px;
                        line-height: 140%;
                        letter-spacing: 0.1em;
                    }
                }
                .more-card {
                    flex-basis: 25%;
                    flex-direction: column;
                    margin: 0 20px;
                    justify-content: flex-start;

                    a {
                        transform: translateY(-100px);
                    }
                }
            }
        }
    }
}

// Phone
@media only screen and (min-device-width: $phone) and (max-device-width: $desktop) {
    .services-container {
        display: flex;
        flex-wrap: wrap;

        .heading-wrapper {
            width: 100%;
            justify-content: center;
            margin: 0 0 0 0;
            align-content: space-evenly;
            padding: 50px 0;

            .heading-container {
                justify-content: flex-start;
                width: 90%;
                margin: 2rem 0;
                position: relative;
                top: unset;
                left: unset;
                max-width: unset;

                h1 {
                    text-align: left;
                    font-size: 38px;
                }
                img {
                    right: 45%;
                }
            }
            .heading-text {
                width: 100%;
                margin-right: unset;
                margin-top: 10px;
                padding: 0 20px;
                text-align: center;

                .container {
                    justify-content: center;
                }
            }
        }
        .our-services {
            width: 100%;
            transform: translateY(0);
            padding: 2rem 0;
            border: none;

            .col {
                width: 100%;
                justify-content: center;

                .row {
                    .service-card {
                        margin: 10px 0;
                        flex-basis: 90%;

                        .card-label {
                            top: -15px;
                            left: 0;
                        }
                        p {
                            text-align: center;
                        }
                    }
                    .more-card {
                        flex-basis: 90%;
                    }
                }
            }
        }
    }
}

//Tablet
@media only screen and (min-device-width: $tablet) and (max-device-width: $desktop) {
    .services-container {
        flex: 1 1 100%;
        margin-top: 50px;

        .heading-wrapper {
            width: 100%;
            margin: 1rem 0 5rem 0;
            background-color: $button-main;
            min-height: 200px;
            justify-content: flex-end;
            align-content: baseline;
            padding-bottom: 150px;

            .heading-container {
                width: 60%;
                img {
                    right: 60%;
                }
            }
        }
        .our-services {
            transform: translateY(-200px);
            background-color: white;
            padding-top: 4rem;
            padding-bottom: 1rem;

            .col {
                .row {
                    margin: 0 15px;
                    justify-content: space-evenly;

                    .service-card {
                        flex-direction: column;
                        margin: 0 20px;
                        flex-basis: 25%;

                        .card-label {
                            padding: 8px 5px;
                            position: absolute;
                            top: 25px;
                            left: -40px;
                        }
                    }
                    .more-card {
                        margin: 0 20px;
                        flex-basis: 28%;

                        .imgWrap {
                            a {
                                transform: translateY(-70px);
                                button {
                                    padding: 15px 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

//Tablet landscape
@media only screen and (min-device-width: $tablet) and (max-device-width: $desktop) and (orientation: landscape) {
    .services-container .our-services .col .row .service-card .card-label {
        padding: 8px 25px;
    }
    .services-container .our-services .col .row .more-card .imgWrap a button {
        padding: 20px;
    }
}
