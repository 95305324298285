@import "../../App.scss";

.form-container {
    flex-basis: 100%;
    margin-top: 2rem;
    flex: 1 1 auto;
    font-family: Helvetica;

    .search-form {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        padding: 40px 5px 0 5px;
        background-color: $select-background;
        border: 1px solid $table-border;
        box-sizing: border-box;
        font-family: Helvetica;

        .search-amount {
            flex-direction: column;
            align-content: flex-start;
            align-items: flex-start;
            padding: 1rem 2rem;

            p {
                font-size: 20px;
                line-height: 110%;
                color: $select-text;
            }
        }
        .search-fields {
            align-items: flex-start;

            .field-group {
                align-items: flex-start;
                flex: 1 1 15%;
                margin: 0 1.5rem;

                label {
                    display: none;
                }
                input {
                    min-height: 50px;
                    padding-left: 1rem;
                    width: 200px;
                    background-color: $select-background;
                    border: 0.6px solid $form-border;
                    box-sizing: border-box;
                    box-shadow: 0px 12px 20px rgba(64, 45, 27, 0.1);
                    color: $text-primary;
                }
                select {
                    -webkit-appearance: none;
                    min-height: 50px;
                    padding-left: 1rem;
                    width: 200px;
                    background-color: $select-background;
                    border: 0.6px solid $form-border;
                    box-sizing: border-box;
                    box-shadow: 0px 12px 20px rgba(64, 45, 27, 0.1);

                    color: $text-primary;
                }
                .form-input-container {
                    width: 100%;

                    input,
                    select {
                        color: $input-color;
                    }
                }
            }
        }

        .form-container-button {
            justify-content: center;
            padding: 0 55px;
            flex-direction: column;

            button {
                margin: 0.25rem 0 1.5rem 0;
                flex: unset;
                width: 200px;
                padding: 15px;
            }
        }
    }
}

// Phone
@media only screen and (min-device-width: $phone) and (max-device-width: $desktop) {
    .form-container {
        .search-form {
            padding: 20px 0px;

            .search-amount {
                justify-content: center;
            }
            .form-container-button {
                padding: 0;
                button {
                    width: 220px;
                }
            }
        }
    }
}

//Tablet
@media only screen and (min-device-width: $tablet) and (max-device-width: $desktop) {
    .form-container {
        width: 100%;
        opacity: 0.95;

        .search-form {
            justify-content: flex-start;

            .search-amount {
                padding: 1rem;
                justify-content: flex-start;
            }
            .search-fields {
                padding: 5% 10%;
                .field-group {
                    flex: 1 1 100%;
                    input,
                    select {
                        width: 180px;
                    }
                }
                .form-container-button {
                    flex: 1 1 100%;
                    flex-direction: row;
                    button {
                        margin: 0 1rem;
                        width: 205px;
                    }
                }
            }
        }
    }
}

//Tablet landscape
@media only screen and (min-device-width: $tablet) and (max-device-width: $desktop) and (orientation: landscape) {
    .form-container {
        width: 100%;

        .search-form {
            justify-content: flex-start;

            .search-amount {
                padding: 1rem;
                justify-content: flex-start;
            }
            .search-fields {
                justify-content: flex-start;
                padding: 40px 5px;
                .field-group {
                    flex: 1 1 20%;
                    margin: 0 0.5rem;
                    input,
                    select {
                        width: 180px;
                    }
                }
                .form-container-button {
                    flex: 1 1 20%;
                    flex-direction: row;
                    button {
                        width: 180px;
                        margin: 0 0 1rem 0;
                    }
                }
            }
        }
    }
}
