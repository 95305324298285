@import "../../App.scss";

.apply-container {
    flex: 1 1 100%;

    .hide {
        display: none !important;
    }
    .container-row {
        flex: 1 1 100%;
        align-items: stretch;
        padding-top: 30px;

        .container-column {
            flex-direction: column;
            flex-basis: 40%;
            margin: 0 10px;
        }

        .container-column.right {
            justify-content: flex-start;
            padding-top: 80px;

            button {
                margin: 10px 0;
                border: 1px solid $button-text;
                box-sizing: border-box;
                box-shadow: 0px 8px 20px rgba(64, 45, 27, 0.1);
                width: 330px;
                font-family: Helvetica;
                font-size: 16px;
                line-height: 110%;
                color: $select-text;
                display: flex;
                align-items: center;

                img {
                    margin-right: 10px;
                }
            }
        }
    }
}

// Phone
@media only screen and (min-device-width: $phone) and (max-device-width: $desktop) {
    .apply-container {
        .container-row {
            .container-column {
                flex-basis: 100%;
            }
            .form-container-head {
                text-align: center;
                align-items: center;
            }
            .container-column.right {
                button {
                    width: 250px;
                }
            }
        }
    }
}

//Tablet
@media only screen and (min-device-width: $tablet) and (max-device-width: $desktop) {
    .apply-container {
        .container-row {
            flex-direction: row;
            .form-container-head {
                text-align: left;
                align-items: flex-start;
            }
            .container-column.left {
                flex: 0 1 60%;
            } 
            .container-column.right {
                flex: 0 1 25%;

                button {
                    justify-content: center;
                }
            }
        }
    }
}
