@import "../includes/settings.scss";

.about-container {
    .container-row {
        width: 100%;
        justify-content: space-evenly;
        padding: 30px 30px;

        .col.left {
            flex: 0 1 20%;
            flex-direction: column;
            .heading-wrapper {
                flex-direction: column;
                flex-basis: 30%;

                .heading-container {
                    width: 200px;
                    hr {
                        transform: translateX(-70px);
                        border-radius: 4px;
                        width: 20%;
                        background-color: $text-secondary;
                        border: 3px solid $text-secondary;
                    }

                    h1 {
                        color: $text-heading;
                        font-weight: bold;
                        font-size: 48px;
                        line-height: 110%;
                        text-align: left;
                        letter-spacing: 0.1em;
                    }
                    img {
                        position: absolute;
                        z-index: -10;
                        right: 15%;
                        bottom: 0;
                    }
                }
            }
            .recruiter-item {
                margin: 2rem 0;
                .karen {
                    width: 100%;
                    height: auto;
                }
                .img-bg {
                    position: absolute;
                    left: -50px;
                    bottom: -10%;
                    z-index: -10;
                }
            }
        }
        .col {
            flex: 0 1 70%;
            .about-text {
                align-items: flex-start;
                flex-direction: column;
                flex-basis: 70%;

                h3 {
                    margin-bottom: 0.5rem;
                }
                h4 {
                    margin-bottom: 0.5rem;
                }
                li {
                    margin-bottom: 0.5rem;
                }
                p {
                    margin: 0.5rem 0 1rem 0;
                    width: 90%;
                    font-family: Helvetica;
                    font-size: 18px;
                    line-height: 160%;
                    letter-spacing: 0.1em;
                }
            }
        }
        .image-container {
            .img-bg {
                position: absolute;
                left: -50px;
                bottom: -7%;
                z-index: -10;
            }
        }
    }
}
// Phone
@media only screen and (min-device-width: $phone) and (max-device-width: $desktop) {
    .about-container {
        .container-row {
            padding: 15px;

            .col.left {
                flex: 1 1 100%;
                .heading-wrapper {
                    margin-bottom: 2rem;
                    flex-basis: 100%;
                    .heading-container {
                        width: 200px;

                        h1 {
                            font-size: 38px;
                        }
                        img {
                            right: 20%;
                            bottom: -5%;
                        }
                    }
                }

                .recruiter-item {
                    margin: 1rem 0;

                    .karen {
                        width: 80%;
                    }

                    .img-bg {
                        display: none;
                    }
                }
            }

            .col {
                flex: 1;
                .about-text {
                    flex: 1;
                    text-align: center;
                    align-items: center;
                
                    p {
                        margin: 1rem 0 2rem 0;
                    }
                    li {
                        margin-bottom: 2rem;
                    }
                }
            }
            .image-container {
                .img-bg {
                    display: none;
                }
                img {
                    max-width: 95%;
                    max-height: 95%;
                }
            }
        }
        .container-row.bottom {
            padding: unset;
        }
    }
}

//Tablet
@media only screen and (min-device-width: $tablet) and (max-device-width: $desktop) {
    .about-container {
        .container-row {
            align-items: end;

            .col.left {
                flex: 1 1 100%;
                flex-direction: row;
                align-items: stretch;
                justify-content: space-evenly;
                margin: 1rem 0 3rem 0;

                .heading-wrapper {
                    justify-content: flex-start;
                    flex: 0 1 20%;
                }

                .recruiter-item {
                    flex: 0 1 50%;
                    margin: 0;

                    .img-bg {
                        bottom: -10%;
                        left: 15%;
                        right: 0;
                        display: flex;
                    }
                }
            }
            .col {
                flex: 1 1 100%;
                .about-text {
                    flex-basis: 100%;
                }
            }

            .image-container {
                .img-bg {
                    display: flex;
                    left: -5%;
                    bottom: -10%;
                }
            }
        }
        .container-row.bottom {
            padding: 30px;
        }
    }
}

//Tablet landscape
@media only screen and (min-device-width: $tablet) and (max-device-width: $desktop) and (orientation: landscape) {
    .about-container {
        .container-row {
            margin: 1rem 0;
            .col.left {
                margin: 0;
                flex: 0 1 30%;

                .heading-wrapper {
                    justify-content: flex-start;
                    flex: 0 1 20%;
                }

                .recruiter-item {
                    flex: 1 1 100%;
                    margin: 0;

                    .karen {
                        width: 100%;
                    }
                    .img-bg {
                        display: flex;
                        left: -5%;
                    }
                }
            }
            .col {
                flex: 0 1 70%;

                .about-text {
                    text-align: right;
                    flex: 1 1 100%;
                    align-items: flex-end;
                }
            }
            .image-container {
                .img-bg {
                    display: flex;
                    left: -2%;
                    bottom: -7%;
                }
            }
        }
        .container-row.bottom {
            padding: 30px;
        }
    }
}
//Large Screen
@media only screen and (min-device-width: 2560px) {
    .recruiters-container {
        .heading-wrapper {
            padding: 0 2.5% !important;
        }
        .row {
            .recruiter-card {
                max-width: 500px !important;
            }
        }
    }
}
