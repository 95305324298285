@import "../../App.scss";
.contact-container {
    flex: 1 1 100%;
    .contact-wrapper {
        margin-top: 100px;
        flex: 1 1 100%;

        .contact-block {
            width: 65%;
            background-color: $button-main;
            padding: 5rem 8rem;
            align-items: stretch;

            .container-col {
                flex: 0 1 50%;
                flex-direction: column;

                .bg-img {
                    position: absolute;
                    right: -10%;
                    top: -3%;
                }
                .container-text {
                    justify-content: flex-start;

                    h3 {
                        width: 100%;
                        margin: 15px 0;
                        color: $text-secondary;
                        font-family: Helvetica;
                        font-weight: bold;
                        font-size: 24px;
                        line-height: 110%;
                        letter-spacing: 0.1em;
                    }

                    p {
                        margin: 15px 0;
                        color: white;
                        font-family: Helvetica;
                        font-weight: bold;
                        font-size: 24px;
                        line-height: 140%;
                        letter-spacing: 0.1em;
                    }
                    .company-reg {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

// Phone
@media only screen and (min-device-width: $phone) and (max-device-width: $desktop) {
    .contact-container {
        .contact-wrapper {
            .contact-block {
                padding: 1rem 0 0 0;
                width: 100%;

                .container-col {
                    flex: 1 1 100%;

                    .container-text {
                        width: 80%;
                        text-align: center;
                        justify-content: center;

                        p {
                            font-size: 1rem;
                        }
                        .company-reg {
                            font-size: 0.75rem;
                        }
                    }

                    .bg-img {
                        display: none;
                    }
                }
            }
        }
    }
}

//Tablet
@media only screen and (min-device-width: $tablet) and (max-device-width: $desktop) {
    .contact-container {
        .contact-wrapper {
            flex-direction: column;
            .contact-block {
                width: 100%;
                padding: 5rem 0rem;
                justify-content: space-evenly;

                .container-col.left {
                    flex-basis: 30%;
                }
                .container-col {
                    flex-basis: 40%;

                    .bg-img {
                        position: absolute;
                        right: -8%;
                        top: -3%;
                    }
                    .container-text {
                        padding: 0 10px;
                        justify-content: center;
                        text-align: center;

                        p {
                            margin: 15px 0 0 0;
                        }
                    }
                }
            }
        }
    }
}
