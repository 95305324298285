// Colors
$primary: #50BC9B;
$secondary: #F58123;
$text-light: white;
$text-dark: #454545;
$text-heading: #333333;
$text-primary: #4F4F4F;
$text-secondary: #75D9D3;
$button-main: #262525;
$button-text: #BDBDBD;
$select-text: #828282;
$select-background: #fbfbfb;
$table-border: #e0e0e0;
$form-border: #67b9b2;
$review-background: #F2F2F2;
$input-color: #797c7d;
$background-color: #3a3939;

// Breakpoints
$phone: 320px;
$tablet: 768px;
$desktop: 1024px;
