.WYSIWUG_Editor {
    display: block;
    flex-wrap: unset;
    position: relative;
    justify-content: unset;
    align-items: unset;
    align-content: unset;
    text-align: inherit;
    flex: unset;
    flex: 1 1 100%;

    div {
        display: block;
        flex-wrap: unset;
        position: relative;
        justify-content: unset;
        align-items: unset;
        align-content: unset;
        flex: unset;
    }

    img {
        width: unset;
        height: unset;
        object-fit: unset;
    }

    .wrapper-class {
        width: 100%;
        background-color: #f4f4f4;
    }

    .toolbar-class {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        .rdw-inline-wrapper,
        .rdw-block-wrapper,
        .rdw-fontsize-wrapper,
        .rdw-fontfamily-wrapper,
        .rdw-list-wrapper,
        .rdw-text-align-wrapper,
        .rdw-colorpicker-wrapper,
        .rdw-link-wrapper,
        .rdw-embedded-wrapper,
        .rdw-emoji-wrapper,
        .rdw-image-wrapper,
        .rdw-remove-wrapper,
        .rdw-history-wrapper {
            display: flex;
            align-items: center;
            margin-bottom: 6px;
            flex-wrap: wrap;

            .rdw-option-wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .rdw-option-wrapper.rdw-fontsize-dropdown,
            .rdw-dropdown-wrapper.rdw-fontfamily-dropdown,
            .rdw-dropdown-wrapper.rdw-block-dropdown,
            .rdw-dropdown-wrapper.rdw-fontsize-dropdown {
                display: flex;
                justify-content: center;
                align-items: center;

                a {
                    width: 90%;

                    .rdw-dropdown-carettoopen,
                    .rdw-dropdown-carettoclose {
                        position: absolute;
                    }
                }

                ul {
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    margin: 2rem 0 0 0;
                }
            }
        }
    }

    .editor-class {
        width: 80%;
        margin: 1% 10%;
        padding: 1%;
        border: 1px solid #e5e5e5;
        background-color: white;
        min-height: 80vh;

        .rdw-center-aligned-block > div,
        .rdw-justify-aligned-block > div,
        .rdw-left-aligned-block > div,
        .rdw-right-aligned-block > div {
            display: inline-block;
        }
    }
}
