@import "../includes/settings.scss";

.home-container {
    .header-container .container-header {
        background-position-y: -200px;
    }
}

// Phone
@media only screen and (min-device-width: $phone) and (max-device-width: $tablet) {
    .home-container {
        .header-container .container-header {
            background-position-y: unset;
        }
    }
}

//Tablet
@media only screen and (min-device-width: $tablet) and (max-device-width: $desktop) {
    .home-container {
        .header-container .container-header {
            background-position-y: unset;
        }
    }
}
//Tablet landscape
@media only screen and (min-device-width: $tablet) and (max-device-width: $desktop) and (orientation: landscape) {
    .home-container {
        .header-container .container-header {
            background-position-y: -75px;
        }
    }
}
