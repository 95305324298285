@import "../includes/settings.scss";
.jobDetails-container {
    flex: 1 1 100%;
}

// Phone
@media only screen and (min-device-width: $phone) and (max-device-width: $tablet) {
    .home-container {
        .header-container .container-header {
            background-position-y: unset;
        }
    }
}
