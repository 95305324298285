@import "../../App.scss";
.results-container {
    flex: 1 1 100%;
    margin-top: 50px;
    flex-direction: column;
    width: 100%;
    table,
    tbody,
    tr,
    td {
        display: flex;
    }
    .hide {
        display: none;
    }
    .col-table-heading {
        padding: 25px 100px;
        transform: translateY(35px);
        z-index: 100;
        background-color: $text-secondary;
        font-family: Helvetica;
        font-weight: bold;
        font-size: 18px;
        line-height: 150%;
        color: $text-heading;
    }

    .container-table {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;

        .container-row-table {
            background: $select-background;
            border: 1px solid $table-border;
            box-sizing: border-box;
            width: 85%;
            flex-direction: column;
            padding: 20px;

            .info-col {
                padding-top: 20px;
                justify-content: space-between;
                flex-basis: 80%;

                .job-description {
                    margin: 10px;
                }

                .table-col {
                    align-items: end;
                    align-content: flex-start;
                    margin: 10px;
                    flex-direction: column;
                    font-family: Helvetica;
                    width: 150px;
                    h3 {
                        margin: 10px 0;
                        font-size: 16px;
                        line-height: 110%;
                        color: $select-text;
                    }
                    p {
                        font-size: 18px;
                        line-height: 120%;
                        color: $text-heading;
                    }
                }
                .table-col-button {
                    flex-direction: column;

                    button {
                        margin: 10px 0;
                        padding: 15px 50px;
                    }
                }
            }
            .description-col {
                width: 85%;
                justify-content: flex-start;
                text-align: left;
            }
            .num-pages {
                font-family: Helvetica;
                font-size: 18px;
                line-height: 110%;

                strong {
                    color: $text-secondary;
                }
            }

            .change-page {
                p {
                    margin-bottom: 15px;
                }
            }
        }
        .bottom {
            justify-content: space-between;
        }
    }
    .container-table.pagination {
        margin-top: unset;
    }
    .hide {
        display: none;
    }
}
// Phone
@media only screen and (min-device-width: $phone) and (max-device-width: $desktop) {
    .results-container {
        margin-top: unset;
        display: flex;
        flex-wrap: wrap;

        .col-table-heading {
            padding: 15px 50px;
            z-index: 100;
            background-color: $text-secondary;
            font-family: Helvetica;
            font-weight: bold;
            font-size: 18px;
            line-height: 150%;
            color: $text-heading;
        }
        .container-table {
            .container-row-table {
                width: 100%;
                flex-direction: column;

                .info-col {
                    flex-direction: column;
                    justify-content: center;
                    flex-basis: 100%;
                    align-items: center;

                    .table-col-button,
                    .table-col {
                        align-items: center;
                        text-align: center;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        h3 {
                            font-size: 18px;
                        }
                    }
                }
                .description-col {
                    width: 100%;
                    justify-content: center;
                    text-align: center;
                }
            }
        }
    }
}

//Tablet
@media only screen and (min-device-width: $tablet) and (max-device-width: $desktop) {
    .results-container {
        .container-table {
            margin: 1rem 0;
            .container-row-table {
                width: 95%;
                padding: 10px;

                .info-col {
                    flex-direction: row;
                    margin: 5px;
                    align-items: flex-start;

                    .table-col {
                        flex-basis: 30%;
                    }
                    .table-col-button {
                        a {
                            button {
                                margin: 5px 0;
                                padding: 10px 30px;
                            }
                        }
                    }
                }
                .description-col {
                    width: 65%;
                    justify-content: flex-start;
                    text-align: left;
                }
            }
        }
    }
}
