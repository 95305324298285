.List-Container {
    flex: 1 1 100%;

    .list-navbar {
        display: flex;
        flex: 1 1 100%;
        justify-content: flex-end;

        ul {
            list-style: none;
            margin: 1rem 0;

            li {
                margin: 0 0.5rem;
            }
        }
    }

    .list {
        display: block;
        width: 100%;
        // overflow-x: auto;
        -webkit-overflow-scrolling: touch;

        table {
            width: 100%;
            margin-bottom: 1rem;
            color: #212529;
            border-collapse: collapse;

            th, td { 
                padding: .75rem;
                vertical-align: middle;
                border-top: 1px solid #dee2e6;
                text-align: center;
            }

            thead {
                th {
                    vertical-align: bottom;
                    border-bottom: 2px solid #dee2e6;
                }
            }

            tbody {
                tr:nth-of-type(odd) {
                    background-color: rgba(0,0,0,.05);
                    &:hover {
                        background-color: rgba(0,0,0,.1);
                        cursor: pointer;
                    }
                }
            }

            tr {
                &:hover {
                    background-color: rgba(0,0,0,.1);
                    cursor: pointer;
                }
            }
        }
    }
}
