@import "../../App.scss";
.footer-container {
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    margin-top: 100px;

    .footer-top {
        width: 100%;
        justify-content: space-between;
        align-items: stretch;
        flex-direction: row;
        padding: 0;

        .footer-col {
            padding: 5% 0;
            flex-direction: column;
        }

        .item-left {
            background-color: $button-main;
            flex: 1 1 60%;
            align-items: baseline;

            .footer-main {
                flex-direction: column;
                color: $text-light;
                align-items: baseline;
                margin-left: 4rem;
                font-family: Helvetica;
                h3 {
                    font-size: 24px;
                    line-height: 160%;
                    letter-spacing: 0.1em;
                    margin: 1rem 0;
                    color: $text-secondary;
                }
                p {
                    font-size: 18px;
                    line-height: 160%;
                    letter-spacing: 0.1em;
                }

                .subscribe-form {
                    width: unset;
                    display: flex;
                    flex-wrap: wrap;

                    .form-container-button {
                        align-content: flex-start;
                    }
                    .field-group {
                        margin: 0;

                        .form-input-container {
                            flex-direction: column;
                            align-content: flex-start;

                            input {
                                margin: 1rem 0;
                                cursor: auto;
                                padding: 15px 80px;
                                font-size: 14px;
                                line-height: 160%;
                                opacity: 0.9;
                                letter-spacing: 0.1em;
                            }
                        }
                    }

                    button {
                        margin: 1rem;
                        color: $text-secondary;
                        padding: 15px 80px;
                        font-weight: bold;
                        font-size: 20px;
                    }

                    .form-success {
                        flex-direction: column;
                        align-items: baseline;
                        h1 {
                            margin-bottom: 1rem;
                        }
                        p {
                            color: $text-secondary;
                        }
                    }
                }
            }
        }
        .item-right {
            flex: 1 1 40%;

            background-image: url("../../../assets/webAssets/footer.png");
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;

            img {
                width: 80%;
            }
        }
    }
    .foot-bottom {
        flex: 1 1 100%;
        width: 100%;
        background-color: white;
        padding: 2rem 0;

        .container-row {
            margin-top: 4rem;
            margin-bottom: 1rem;
            width: 100%;
            justify-content: space-evenly;
            align-items: flex-start;

            .footer-logo {
                img {
                    width: 175px;
                }
            }
            .footer-col {
                flex-direction: column;
                align-items: flex-start;
                font-family: Helvetica;
                font-size: 18px;
                line-height: 110%;
                letter-spacing: 0.02em;
                
                h2 {
                    margin-bottom: 15px;
                    font-weight: 700;
                    line-height: 20px;
                }
                
                a {
                    font-weight: 400;
                    color: black;
                    margin: 10px 0;
                }

                .socials-container {
                    display: flex;
                    img {
                        height: 15px;
                        width: 15px;
                        margin: 10px;
                        padding: 3px;
                        border-radius: 3px;
                        border: 3px solid $text-secondary;
                        cursor: pointer;

                        &:hover {
                            background-color: $select-background;
                        }
                    }
                }
            }
        }
    }
    .footer-disclaimer {
        width: 100%;
        height: 50px;
        background-color: $button-main;
        justify-content: space-between;
        p {
            color: $text-light;
            font-family: Gilroy;
            font-size: 14px;
            line-height: 17px;
            margin: 0 4rem;
        }
        a {
            margin: 0 4rem;
            color: $text-light;
            font-size: 14px;
            line-height: 17px;
        }
    }
}

// Phone
@media only screen and (min-device-width: $phone) and (max-device-width: $desktop) {
    .footer-container {
        display: flex;
        flex-wrap: wrap;

        .footer-top {
            justify-content: center;

            .footer-col {
                .footer-main {
                    align-items: center;
                    margin-left: unset;
                    h3 {
                        font-size: 14px;
                    }
                    h1 {
                        margin: 2rem 0;
                        font-size: 30px;
                    }
                    p {
                        width: 80%;
                        font-size: 16px;
                    }
                    .subscribe-form {
                        justify-content: center;
                        .field-group .form-input-container {
                            align-content: center;

                            input {
                                padding: 1rem;
                                max-width: 100%;
                            }
                        }
                        .form-success {
                            align-items: center;
                        }
                    }
                }
            }
            .item-left {
                text-align: center;
            }
            .item-right {
                height: 300px;
            }
        }
        .foot-bottom {
            .container-row {
                margin-top: unset;
                margin-bottom: unset;
                justify-content: space-evenly;
                align-items: flex-start;

                .footer-col {
                    width: 100%;
                    align-items: center;
                    margin: 20px 0;
                    font-size: 16px;
                    h2 {
                        margin-bottom: 13px;
                    }
                    a {
                        margin: 5px 0;
                    }

                    .socials-container {
                        justify-content: center;
                    }
                }
                .footer-logo {
                    margin-top: 0;
                }
            }
        }
        .footer-disclaimer {
            justify-content: center;
            height: 75px;

            a,
            p {
                margin: 0.25rem 0;
            }
        }
    }
}

//Tablet
@media only screen and (min-device-width: $tablet) and (max-device-width: $desktop) {
    .footer-container {
        flex: 1 1 100%;
        margin-top: 100px;

        .footer-top {
            width: 100%;
            justify-content: space-between;

            .footer-col {
                flex-direction: column;

                .footer-main {
                    align-items: baseline;
                    margin-left: 4rem;
                    h3 {
                        font-size: 16px;
                    }
                    h1 {
                        font-size: 40px;
                    }
                    p {
                        font-size: 18px;
                        text-align: left;
                    }
                    .footer-form-container {
                        width: unset !important;
                        .subscribe-form {
                            justify-content: flex-start !important;
                            align-items: baseline !important;

                            .field-group .form-input-container {
                                align-content: flex-start;
                            
                                input {
                                    width: 70%;
                                }
                            }

                            .form-container-button {
                                button {
                                    margin: 0 !important;
                                }
                            }
                            .form-success {
                                align-items: baseline !important;
                            }
                        }
                    }
                }
            }
            .item-left {
                padding: unset;
                background-color: $button-main;
                flex: 1 1 60%;
            }
            .item-right {
                flex: 1 1 35%;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        .foot-bottom {
            width: 100%;
            .container-row {
                margin-top: 2rem;
                padding: 0 10px;
                width: 100%;
                justify-content: space-evenly;
                align-items: flex-start;

                .footer-logo {
                    img {
                        width: 175px;
                    }
                }

                .footer-col {
                    text-align: center;
                    flex-basis: unset;
                    flex-direction: column;
                    width: 25%;
                    font-size: 18px;
                    h2 {
                        margin-bottom: 15px;
                        font-size: 20px;
                        font-weight: bold;
                    }
                    a {
                        color: inherit;
                        margin: 10px 0;
                    }

                    .socials-container {
                        display: flex;
                        img {
                            height: 15px;
                            width: 15px;
                            margin: 10px;
                            padding: 3px;
                            border-radius: 3px;
                            border: 3px solid $text-secondary;
                            cursor: pointer;

                            &:hover {
                                background-color: $select-background;
                            }
                        }
                    }
                }
            }
        }
        .footer-disclaimer {
            justify-content: space-between;
            height: 50px;

            a,
            p {
                margin: 0 1rem;
            }
        }
    }
}
