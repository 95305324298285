.blog-container {
    flex: 1 1 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0 1rem;

.container-row {
      height: unset;
      width: unset;
      padding: unset;
  }
    .blog-title {
        flex: 1 1 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 1rem 0;
    }

    .blog-navbar {
        flex: 1 1 100%;
        display: flex;
        flex-direction: row;
        padding: 1rem 0;

        .blog-list {
            flex: 1 1 100%;
            display: flex;
            flex-direction: row;
            list-style: none;
            justify-content: flex-end;

            .blog-item {
                flex: 0 1 auto;
            }
        }
    }


    .PostList-Admin {
        flex: 1 1 100%;
        display: flex;
        flex-direction: row;

        h3 {
            padding: 1rem 0;
        }
    }
}
