@import "../../App.scss";

.login-container {
    flex: 1 1 100%;
    display: flex;
    flex-direction: row;

    .form-container {
        margin: 5% 30%;
        flex: 1 1 80%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;

        form {
            padding: 0 0 2rem 0;
            flex: 1 1 100%;
            justify-content: center;
            background-color: #f5f5f5;
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
            border-radius: 0.5rem;

            .header {
                margin: 0 0 1rem 0;
                flex: 1 1 100%;
                background-color: $text-secondary;

                h1 {
                    flex: 1 1 100%;
                    color: $text-dark;
                    margin: 2rem;
                    font-size: 26px;
                    font-weight: 500;
                    text-align: center;
                }
            }
            .field-group {
                margin: 1rem 10%;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: flex-start;
                align-items: center;
                flex: 1 1 100%;

                label {
                    flex: 1 1 100%;
                    font-size: 14px;
                    line-height: 28px;
                }
                .form-input-container {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    align-items: center;
                    flex: 1 1 auto;

                    input {
                        font-family: inherit;
                        flex: 1;
                        padding: 1rem 0.75rem;
                        box-sizing: border-box;
                        border: 1px solid #231f20;
                        border-radius: 5px;
                        background-color: #fff;
                        margin: 5px 0;
                        transition: border-color 0.15s ease-in-out,
                            box-shadow 0.15s ease-in-out;
                    }
                }
            }
            .button-container {
                .btn-main {
                    background-color: $text-dark;
                    font-family: "Montserrat";
                    font-size: 1.2rem;
                    font-weight: 600;
                    border-color: $text-dark;
                    border-radius: 5px;
                    color: white;
                    padding: 0.5rem 1rem;
                    transition: background-color 0.5s ease-in-out, b;
                }
                .btn-main:hover {
                    background-color: $text-secondary;
                    border-color: $text-secondary;
                }
            }
        }
    }
}

// Phone
@media only screen and (min-device-width: $phone) and (max-device-width: $tablet) {
    .login-container {
        .form-container {
            margin: 5% 0 10% 0;

            form {
                .header {
                    background: unset;
                    background-color: $text-dark;

                    h1 {
                        color: whitesmoke;
                    }
                }
            }
        }
    }
}
//Tablet
@media only screen and (min-device-width: $tablet) and (max-device-width: $desktop) {
    .login-container {
        .form-container {
            margin: 5% 10%;
        }
    }
}
