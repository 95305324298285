@import "../../App.scss";

.submit-container {
    flex: 1 1 100%;
    width: 100%;
    .container-row {
        width: 100%;
        padding: 0 5%;

        .submit-col {
            flex-basis: 40%;
            
            .card-container {
                .box-1 {
                    position: absolute;
                    top: -175px;
                    right: 0;
                    z-index: 5;
                }
                .main-image {
                    margin: 0 2rem;
                }
                .cv-img {
                    transform: translateY(-125px);
                }

                .button-cv {
                    position: absolute;
                    bottom: 30%;
                }
                .button-role {
                    position: absolute;
                    bottom: 5%;
                }
            }
            .background-image {
                position: absolute;
                z-index: -100;
            }
            .cv-bg {
                left: 0;
                bottom: 15%;
            }
            .role-bg {
                top: -15%;
                right: 0%;
            }
        }
    }
}

// Phone
@media only screen and (min-device-width: $phone) and (max-device-width: $desktop) {
    .submit-container {
        display: flex;
        flex-wrap: wrap;

        .container-row {
            width: 100%;
            padding: unset;

            .submit-col {
                flex-basis: 40%;
                
                .card-container {
                    .box-1 {
                        display: none;
                    }
                    margin: 25px 0;
                    button {
                        padding: 15px 50px;
                    }
                    .main-image {
                        width: 340px;
                        margin: unset;
                    }
                    .cv-img {
                        transform: translateY(0);
                    }

                    .button-cv {
                        position: absolute;
                        bottom: 5%;
                    }
                    .button-role {
                        position: absolute;
                        bottom: 5%;
                    }
                }
                .background-image {
                    position: absolute;
                    z-index: -100;
                    max-width: 200px;
                    max-height: 300px;
                }
                .cv-bg {
                    left: -2%;
                    bottom: 4%;
                }
                .role-bg {
                    top: 4%;
                    right: -2%;
                }
            }
        }
    }
}
// XS Phone
@media only screen and (max-device-width: 320px) {
    .submit-container {
        .container-row {
            .submit-col {
                .card-container {
                    .main-image {
                        width: 275px;
                        margin: unset;
                    }
                }
                .background-image {
                    position: absolute;
                    z-index: -100;
                    max-width: 150px;
                    max-height: 2500px;
                }
            }
        }
    }
}

//Tablet
@media only screen and (min-device-width: $tablet) and (max-device-width: $desktop) {
    flex: 1 1 100%;

    .container-row {
        width: 100%;
        padding: 0 150px;

        .submit-col {
            margin: 25px 5px;
            flex-basis: 40%;

            .card-container {
                .main-image {
                    max-width: 420px;
                    max-height: 520px;
                    margin: 0 2rem;
                }
                .cv-img {
                    transform: translateY(-125px) !important;
                }

                .button-cv {
                    position: absolute;
                    bottom: 35% !important;
                }
                .button-role {
                    position: absolute;
                    bottom: 5%;
                }
            }
            .background-image {
                position: absolute;
                z-index: -100;
                max-width: 300px;
                max-height: 400px;
            }
            .cv-bg {
                left: -10% !important;
                bottom: 25% !important;
            }
            .role-bg {
                top: -5% !important;
                right: -10% !important;
            }
        }
    }
}

//Large Screen
@media only screen and (min-device-width: 2560px) {
    .submit-container .container-row .submit-col .cv-bg {
        left: 15%;
    }
    .submit-container .container-row .submit-col .role-bg {
        right: 15%;
    }
}
//Large Screen
@media only screen and (min-device-width: 4560px) {
    .submit-container .container-row .submit-col .cv-bg {
        left: 30%;
    }
    .submit-container .container-row .submit-col .role-bg {
        right: 30%;
    }
}
