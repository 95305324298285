@import "../../includes/settings.scss";

.customForm-container {
    flex: 1 1 100%;
    padding: 5% 25%;

    form {
        box-shadow: 0 0.5rem 1rem #dcdcdc;
        border-radius: 0.5rem;
        border: 1px solid rgba(0, 0, 0, 0.125);
        .header-container {
            height: unset;
            padding: 1rem 0;
            background-image: -webkit-gradient(
                linear,
                left top,
                right top,
                from($primary),
                to(#000000)
            );
            color: white;
        }
        .fields-container {
            padding: 5% 5% 0 5%;
        }

        .button-container {
            flex: 1 1 100%;
            margin: 1rem 0;

            button {
                flex: 0 1 70%;
                background-color: $background-color;

                &:hover {
                    background-color: $primary;
                }
            }
        }
    }
}
