@import "../../App.scss";

.create-form-container {
    margin: 0 10%;
    form {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        background-color: $select-background;
        border: 1px solid $table-border;
        box-sizing: border-box;
        font-family: Helvetica;

        .header {
            flex: 1;
            background-color: $text-dark;
            padding: 1rem 0;
            color: white;
        }
        .fields-container {
            flex: 1;
            flex-direction: column;
            padding: 2rem 10%;

            .column {
                flex-direction: column;
                label {
                    display: flex;
                    flex: 1 1;
                    width: 100%;
                }
            }

            .form-row {
                flex: 1;
                width: 100%;

                .field-group {
                    flex: 1;
                    width: 100%;
                    margin: 1rem 0.5rem;
                    flex-direction: column;

                    label {
                        display: flex;
                        flex: 1;
                        width: 100%;
                    }

                    .form-input-container {
                        flex: 1;
                        width: 100%;

                        textArea,
                        select,
                        input {
                            width: 100%;
                            font-family: inherit;
                            padding: 1rem 0.75rem;
                            flex: 0 0 100%;
                            box-sizing: border-box;
                            border: 1px solid $text-dark;
                            border-radius: 5px;
                            background-color: white;
                            margin: 5px 0px;
                            transition: border-color 0.15s ease-in-out,
                                box-shadow 0.15s ease-in-out;
                            // max-block-size: 58px;

                            &:disabled {
                                // opacity: 0.3;
                            }

                            &:focus {
                                outline: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
    label.required:after {
        content: " *";
        color: #de274f;
    }

    .form-error {
        color: #c72c41;
        min-height: 25px;
        font-size: 14px;
        line-height: 28px;
    }

