@import "../../App.scss";
.container-row {
    width: 100%;
    align-items: flex-start;
    margin-top: 2rem;

    .container-col-left {
        flex-basis: 60%;

        .post {
            flex: 1 1 100%;
            .blog-post {
                flex: 1 1 100%;
                margin: 0 50px 0 0;

                .blog-post-header {
                    width: 100%;
                    align-items: flex-start;
                    align-content: flex-start;

                    h1 {
                        margin: 20px 0;
                        color: $text-heading;
                        font-weight: bold;
                        font-size: 24px;
                        line-height: 150%;
                    }

                    .blog-author {
                        margin-bottom: 1rem;
                        justify-content: flex-start;

                        img {
                            margin-right: 2em;
                        }
                        p {
                            display: flex;
                            margin: 10px 0;
                            color: $text-primary;
                            font-family: Helvetica;
                            font-weight: bold;
                            font-size: 18px;
                            line-height: 110%;

                            strong {
                                margin-left: 1rem;
                                color: $text-secondary;
                            }
                        }
                    }
                }
                .img {
                    width: 100%;
                }
            }
            .blog-post-content {
                width: 100%;
                margin-top: 2rem;

                a {
                    color: #1a0dab;
                }
                ol {
                    padding-inline-start: 20px;
                    li {
                        list-style-type: decimal;
                    }
                }
                ul {
                    padding-inline-start: 20px;
                    li {
                        list-style-type: disc;
                    }
                }

                p,
                h1,
                h2,
                h3,
                h4,
                h5,
                h6,
                ol,
                ul,
                li,
                span {
                    width: 100%;
                    margin: 5px 0;
                    color: $text-primary;
                }

                img {
                    max-width: 100%;
                }
                h1 {
                    margin: 2rem 0;
                }
                p {
                    margin: 15px 0;
                }
            }
        }
        .button-container {
            flex: 1 1 100%;
            justify-content: flex-start;
            margin: 2rem 0;
        }
    }

    .container-col-right {
        flex-basis: 20%;
        flex-direction: column;
        font-family: "Raleway";
        line-height: 150%;
        font-size: 18px;

        .card {
            flex-basis: unset;
            margin: 1rem;
            font-family: Raleway;

            .img {
                height: 270px;
                min-height: unset;
                max-height: unset;
                img {
                    height: 270px;
                }
                h5 {
                    position: absolute;
                    bottom: 10%;
                    left: 5%;
                    z-index: 1;
                    font-size: 14px;
                    line-height: 110%;
                    letter-spacing: 0.1em;
                    font-weight: 600;
                    left: 5%;
                    color: $text-light;
                }
                h4 {
                    font-weight: 900;
                    letter-spacing: 0;
                    line-height: 19px;
                    position: absolute;
                    bottom: 14%;
                    left: 5%;
                    font-size: 24px;
                    z-index: 1;
                    color: $text-light;
                }
            }
            .img:after {
                position: absolute;
                height: 100%;
                width: 100%;
                background-color: rgba(0, 0, 0, 0.2);
                top: 0;
                left: 0;
                display: block;
                content: "";
            }
        }

        .buttons {
            display: flex;
            margin: 2rem 0;
        }
        .related-header {
            margin: 1rem 0;
            font-size: 24px;
            line-height: 110%;
            color: $text-secondary;
        }
        .card-wrapper {
            padding: 1rem;
            box-sizing: border-box;
            background: $select-background;

            .react-reveal {
                background: $select-background;
            }

            .posts-container {
                margin: unset;
            }
            .react-blogs-container {
                width: 100%;
                margin: unset;

                .filters {
                    display: none;
                }

                .blog-card {
                    flex-basis: 100%;
                    margin: 10px 0;

                    box-shadow: unset;

                    .img {
                        display: flex;
                        flex-direction: column;
                        font-family: Helvetica;
                        letter-spacing: 0.1em;

                        min-height: unset !important;
                        max-height: 300px;

                        align-items: flex-start;
                        color: $text-light;
                        font-weight: bold;

                        h4 {
                            position: absolute;
                            bottom: 15%;
                            left: 5%;
                            z-index: 1;
                            font-weight: bold;
                            font-size: 20px;
                            line-height: 140%;
                        }
                        h5 {
                            position: absolute;
                            bottom: 2%;
                            left: 5%;
                            z-index: 1;
                            font-size: 12px;
                            line-height: 110%;
                            letter-spacing: 0.1em;
                        }
                    }
                    .img:after {
                        position: absolute;
                        height: 100%;
                        width: 100%;
                        background-color: rgba(0, 0, 0, 0.5);
                        top: 0;
                        left: 0;
                        display: block;
                        content: "";
                    }

                    .content {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 3; /* number of lines to show */
                        -webkit-box-orient: vertical;

                        margin: 1rem 0;
                        padding: 0;
                    }
                }
            }
            .blog-link-wrapper {
                width: 100%;
            }
        }
    }
}
.post-container .container-row .container-col-right .card .img h5 {
    bottom: 2%;
}
// Phone
@media only screen and (min-device-width: $phone) and (max-device-width: $desktop) {
    .container-row .container-col-left {
        flex: 1;
        align-items: center;

        .post {
            .blog-post {
                margin: unset;
                .blog-post-header {
                    align-items: center;

                    .blog-author {
                        width: 100%;
                        justify-content: center;

                        img {
                            max-width: 40px;
                            max-height: 50px;
                            margin-right: 10px;
                        }
                    }
                    .img {
                        img {
                            max-width: 80%;
                        }
                    }
                }

                .blog-post-content {
                    width: 90%;
                    margin: 1rem 0;
                }
            }
        }
    }

    .container-row .container-col-left .button-container {
        justify-content: center;
    }
    .container-row .container-col-right {
        flex-basis: 100%;

        .buttons {
            display: none;
        }
    }
}

//Tablet
@media only screen and (min-device-width: $tablet) and (max-device-width: $desktop) {
    .container-row {
        margin: 0 1rem;

        .container-col-right {
            flex-basis: 100%;

            .card-wrapper {
                padding: 0;
            }
            .card {
                margin: 0 0.5rem;
                flex-basis: 25%;

                .img {
                    h4,
                    h5 {
                        padding: 0 1rem;
                    }
                    h5 {
                        bottom: 1%;
                    }
                }
            }
        }
        .container-col-left {
            .post {
                .blog-post {
                    .blog-post-header {
                        align-items: flex-start;

                        .blog-author {
                            justify-content: flex-start;
                        }
                    }
                    .blog-post-content {
                        margin: 1rem;
                    }
                }
            }
        }
    }
}

//Tablet
@media only screen and (min-device-width: $tablet) and (max-device-width: $desktop) and (orientation: landscape) {
    .container-row .container-col-right .card .img h4 {
        bottom: 15%;
    }
}
//Large Screen
@media only screen and (min-device-width: 2560px) {
    .blog-post .blog-post-header .img {
        margin: 0;
    }
}
