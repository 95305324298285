@import "../../App.scss";

.job-container {
    flex: 1 1 100%;
    width: 100%;

    .table-mobile {
        display: none;
    }
    .job-header {
        flex-direction: column;
        flex: 1 1 100%;
        align-items: flex-start;
        align-self: flex-start;
        align-content: flex-start;
        padding: 3% 10% 1% 10%;

        .job-title {
            flex: 1 1 100%;
            justify-content: flex-start;
            padding-left: 2rem;
        }

        .job-type {
            margin: 0 0 0 2rem;
            padding: 0.5rem 1rem;
            background: rgba(117, 217, 211, 0.2);
            border: 1px solid #67b9b2;
            box-sizing: border-box;

            font-family: Helvetica;
            font-style: normal;
            color: #67b9b2;
            font-weight: bold;
            font-size: 16px;
            line-height: 150%;
        }
    }
    .row {
        flex: 1 1 100%;
        padding: 0 8%;
        align-items: stretch;

        .col {
            flex-direction: row;
            align-items: stretch;
            margin: 0 2rem 2rem 2rem;
            background: $select-background;
            border: 1px solid $table-border;
            box-sizing: border-box;
        }
        .job-table {
            align-content: stretch;
            flex: 1 1 60%;
            min-height: 400px;

            .left {
                background: $text-dark;
                color: white;
                flex: 1 1 20%;
                align-items: flex-start;
                align-content: flex-start;
                padding-left: 2rem;
                flex-direction: column;
                justify-content: space-evenly;

                h2 {
                    font-weight: 400;
                    font-size: 20px;
                    font-family: Helvetica;
                }
                .box-1 {
                    position: absolute;
                    left: -60px;
                    bottom: -50px;
                }
            }
            .right {
                padding-left: 2rem;
                align-items: flex-start;
                align-content: flex-start;
                flex: 1 1 70%;
                flex-direction: column;
                justify-content: space-evenly;
            }
        }
        .team-member {
            flex: 1 1 20%;
            font-family: Helvetica;
            line-height: 150%;
            font-size: 18px;

            .img-wrapper {
                width: 100%;
                img {
                    width: 100%;
                }
            }
            .card-label {
                flex-direction: column;
                position: absolute;
                bottom: 5%;
                right: -5%;

                p {
                    color: $text-heading;
                    font-family: Helvetica;
                    font-size: 12px;
                    line-height: 170%;
                }
                h3 {
                    color: white;
                    font-family: Helvetica;
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 170%;
                }
                color: white;
                background-color: $form-border;
                padding: 8px 30px;
                font-weight: 500;
                font-size: 15px;
                line-height: 110%;
                letter-spacing: 0.1em;
            }

            .card-wrapper {
                padding: 0 20px;
                text-align: left;
                justify-content: center;

                p {
                    margin: 20px 10px;
                    font-family: Helvetica;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 140%;
                }
                button {
                    padding: 20px 60px;
                    margin: 20px 0;

                    border: 1px solid #4f4f4f;
                    box-sizing: border-box;
                    box-shadow: 0px 8px 20px rgba(64, 45, 27, 0.1);
                }
            }
        }
    }
    .multiCol-container {
        flex: 1 1 100%;
        align-items: flex-start;
        margin-left: 6%;

        .multiCol-header {
            margin-bottom: 0.5rem;
            align-items: flex-start;
            flex-direction: column;
            width: 100%;
            font-family: Helvetica;
            font-style: normal;
            font-weight: bold;
            line-height: 150%;

            h1 {
                margin: 1rem 0;
            }
            h4 {
                margin: 10px 0;
                color: $text-primary;
                font-weight: bold;
                font-size: 24px;
                line-height: 150%;
                width: 100%;
            }
        }
        .multiCol-row {
            margin-right: 20%;

            font-family: Helvetica;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 170%;
        
            .col {
                justify-content: flex-start;
            }
        }
    }
    .apply-button-container {
        flex: 1 1 100%;
        padding: 0 10%;
        flex-direction: column;
        align-content: flex-end;

        button {
            width: 275px;
            margin: 0.5rem 0;
        }
        .btn-main {
            padding: 15px 60px;
        }
        .btn-share {
            img {
                margin-right: 1rem;
            }
        }
    }
    .job-post-content {
        font-family: "Raleway";
        margin-top: 2rem;
        padding: 0 10%;
        width: 100%;
        flex: 1 1 100%;
        padding-right: 20%;

        a {
            color: #1a0dab;
        }
        ol {
            padding-inline-start: 20px;
            li {
                list-style-type: decimal;
            }
        }
        ul {
            padding-inline-start: 20px;
            li {
                list-style-type: disc;
            }
        }

        p,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        ol,
        ul,
        li,
        span {
            width: 100%;
            margin: 5px 0;
            color: $text-primary;
        }

        img {
            max-width: 100%;
        }
        h1 {
            margin: 2rem 0;
        }
        p {
            margin: 15px 0;
        }
    }
}
// Phone
@media only screen and (min-device-width: $phone) and (max-device-width: $desktop) {
    .job-container {
        .job-table {
            display: none;
        }
        .job-header {
            padding: unset;
            margin: 1rem 0;
            align-self: center;
            align-content: center;
        }
        .box-1 {
            display: none;
        }
        .row {
            flex-direction: column-reverse;

            .team-member {
                flex: 1 1 100%;
                margin: 0 0 2rem 0;
            }
        }
        .multiCol-container {
            margin-left: 0;

            .multiCol-row {
                margin-right: 0;

                .paragraph {
                    margin: 0.5rem 0;
                }
            }
        }
        .table-mobile {
            display: flex;
            width: 100%;

            background: $select-background;
            border: 1px solid $table-border;
            box-sizing: border-box;

            .table-row {
                width: 100%;
                flex-direction: column;
                text-align: center;
                margin: 20px 0;

                .table-col {
                    margin: 10px;
                    flex-direction: column;
                    font-family: Helvetica;
                    align-items: center !important;
                    text-align: center;
                    width: 100%;

                    .tick {
                        list-style-image: url(../../../assets/icons/tick.svg);
                    }
                    li {
                        font-family: Helvetica;
                        margin: 10px 0;
                        text-align: center;
                        color: $text-heading;
                        font-size: 18px;
                        line-height: 120%;
                    }
                    h3 {
                        margin: 10px 0;
                        font-size: 16px;
                        line-height: 110%;
                        color: $select-text;
                        font-size: 18px;
                    }
                    p {
                        padding: 0 5px;
                        font-size: 18px;
                        line-height: 120%;
                        color: $text-heading;
                    }
                }
            }
        }
        .apply-button-container {
            padding: unset;
            margin: 2rem 0;
            align-content: center;
            button {
                margin: 0.5rem 0;
            }
        }
    .job-post-content {
        padding: 0 10%;

    }
    }
}
//Tablet
@media only screen and (min-device-width: $tablet) and (max-device-width: $desktop) {
    .job-container {
        .job-header {
            padding: 3% 8% 0 8%;
            align-self: flex-start;
            align-content: flex-start;
        }
        .row {
            flex-direction: row;

            .team-member {
                flex: 1 1 10%;
                margin-bottom: 0;
            }
            .job-table {
                display: flex;
                flex: 1 1 50%;
                margin: 0 1rem 0 0;

                .left {
                    padding-left: 0.5rem;
                    flex: 1 1 15%;

                    h2 {
                        font-size: 18px;
                    }
                }
                .right {
                    padding-left: 1rem;
                    flex: 1 1 50%;
                }
            }
        }
        .table-mobile {
            display: none;
        }
        .multiCol-container {
            margin-left: 3%;

            .multiCol-row {
                margin-right: 10%;
            }
        }
        .apply-button-container {
            margin: 1rem 0;
            flex-direction: row;
            justify-content: center;
            button {
                width: 280px;
                margin-right: 2rem;
            }
        }
    .job-post-content {
        padding: 0 15% 0 10%;

    }
    }
}
