@import "../../App.scss";

.view-container {
    flex: 1 1 auto;
    flex-direction: column;

    h1 {
        margin: 1rem 0;
        color: black;
        font-size: 35px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 54px;
    }
    .item {
        flex: 1 1 auto;
    }
    button {
        margin-left: 1.5rem;
    }
    .card {
        background-color: white;
        flex-direction: column;
        width: 80%;
        margin: 2rem 0;
        align-items: flex-start;
        align-content: flex-start;
        background-clip: border-box;
        filter: drop-shadow(0 0 10px rgba(35, 31, 32, 0.13));

        img {
            width: 100%;
            display: flex;
            align-self: center;
        }
    }
    .card-top {
        background-color: gray;
        width: 100%;
        justify-content: flex-start;
        h2 {
            font-weight: 500;
            padding: 1rem;
            margin: 0.5rem 0;
            color: white;
        }
    }
    .card-bottom {
        flex-direction: row;
        width: 100%;
        align-items: flex-start;
        justify-content: flex-start;

        h3 {
            font-weight: 500;
            padding: 1rem;
            margin: 1rem;
        }

        .col {
            flex-direction: column;
            width: 50%;
            align-items: flex-start;
            align-content: flex-start;
        }
    }
    .card-bottom.header {
        h3 {
            margin: 0.5rem;
        }
    }
}

// Phone
@media only screen and (min-device-width: $phone) and (max-device-width: $desktop) {
    .view-container {
        .item {
                margin-top: 4rem;
            }
            .card {
                width: 90%;
           
               img {
                  max-width: 100%;
              } 
                .card-bottom {
                    .col {
                        width: 100%;
                    }
                }
            }
    }
}
