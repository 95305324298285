@import "../includes/settings.scss";

.Legal {
    .container {
        display: flex;
        flex-direction: column;
        margin: 2rem 9%;

        .header {
            justify-content: center;
            flex: 1 1 auto;
            width: 100%;
        }

        .item {
            flex-direction: column;
            width: 100%;
            justify-content: flex-start;
            align-items: flex-start;
        }
        h2 {
            font-size: 50px;
            line-height: 60px;
            text-align: center;
            margin-bottom: 1rem;
        }

        p {
            width: 100%;
            margin-bottom: 1em;
        }

        h3, h4, h5, h6 {
            margin-bottom: 0.5em;
        }

        h3 {
            font-size: 24px;
            line-height: 34px;
        }

        h4 {
            font-size: 20px;
            line-height: 28px;
        }

        h5 {
            font-size: 18px;
            line-height: 24px;
        }

        h6 {
            font-size: 16px;
            line-height: 18px;
        }

        ul, ol {
            counter-reset: list;
            li {
                list-style: none;
                margin-bottom: 1em;

                &::before {
                    content: counter(list, lower-alpha) ") ";
                    counter-increment: list;
                }
            }
        }

        .table-responsive {
            display: block;
            width: 100%;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            -ms-overflow-style: -ms-autohiding-scrollbar;
        }

        table {
            width: 100%;
            max-width: 100%;
            margin-bottom: 1rem;
            background-color: transparent;
            border: 1px solid #dee2e6;
            box-sizing: border-box;
            border-collapse: collapse;

            th,
            td {
                padding: 0.75rem;
                vertical-align: top;
                border: 1px solid #dee2e6;
            }
        }
    }
}
// Phone
@media only screen and (min-device-width: $phone) and (max-device-width: $desktop) {
    .Legal {
        .container {
            margin: 1rem 0;
            flex: 0 1 95%;

            .item {
                flex: 1 1 100%;
            }
            h2 {
                font-size: 35px;
                line-height: 50px;
            }

            p {
                margin-bottom: 2rem;
            }
            h3 {
                margin-bottom: 0.5em;
                font-size: 20px;
                line-height: 24px;
            }
            ol {
                counter-reset: list;

                li {
                    list-style: none;
                    margin-bottom: 1em;

                    &::before {
                        content: counter(list, lower-alpha) ") ";
                        counter-increment: list;
                    }
                }
            }
        }
    }
}
// Large
@media only screen and (min-width: 2100px) {
    .Legal {
        .header-container {
            .container-header {
                .container {
                    margin: 0;
                }
            }
        }
    }
}
