@import "../../App.scss";

.blogList-container {
    .react-blogs-container {
        flex: 1 1 100%;

        .filters {
            flex: 1;
            background-color: unset;
            margin: 1rem 0;
            justify-content: flex-end;

            .filterSelect-form {
                width: unset;
                align-items: center;
                flex: 0 1 40%;
                padding: 0 5.5%;
                justify-content: flex-end;

                .field-group {
                    flex: 0 1 40%;
                    margin: 0 2rem;

                    label,
                    .form-error {
                        display: none;
                    }
                    .form-input-container {
                        select {
                            background-color: $select-background;
                            border: 1px solid $select-text;
                            padding: 1.25rem 0.75rem;
                            font-size: 16px;
                            color: $select-text;
                            box-sizing: border-box;
                            border-radius: 0;
                            margin: 0;
                            transition: unset;
                        }
                    }
                }
                button {
                    flex: 0 1 30%;
                    border-radius: 0;
                    padding: 1.25rem 0.75rem;
                    color: $text-light;
                    font-size: 15px;
                    font-weight: bold;
                    background-color: $button-main;
                    cursor: pointer;
                    border: 1px solid $button-text;

                    &:hover {
                        color: gray;
                    }
                    &:focus {
                        box-shadow: 0 0 0 0.2rem rgb(117, 217, 211);
                    }
                }
            }
        }
        .posts-container {
            justify-content: flex-start;

            .container-col {
                flex-basis: 30%;
                margin: 1rem;
                max-height: 400px;
                min-height: 310px;
                overflow: hidden;

                .blog-card {
                    box-shadow: unset !important;
                    flex-basis: 100%;
                    min-height: 310px;
                    justify-content: flex-start;

                    .blog-link-wrapper {
                        width: 100%;
                    }
                    .content {
                        padding: 1rem 0;
                        p {
                            color: $text-heading;
                            font-size: 14px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                        }
                    }
                    .img {
                        font-family: Helvetica;
                        letter-spacing: 0.1em;

                        min-height: unset !important;
                        height: 200px;

                        color: $text-light;
                        font-weight: bold;

                        h4 {
                            position: absolute;
                            bottom: 15%;
                            left: 5%;
                            z-index: 1;
                            font-weight: bold;
                            font-size: 24px;
                            line-height: 140%;
                        }
                        h5 {
                            position: absolute;
                            bottom: 10%;
                            left: 5%;
                            z-index: 1;
                            font-size: 14px;
                            line-height: 110%;
                            letter-spacing: 0.1em;
                        }
                    }
                    .img:after {
                        position: absolute;
                        height: 100%;
                        width: 100%;
                        background-color: rgba(0, 0, 0, 0.5);
                        top: 0;
                        left: 0;
                        display: block;
                        content: "";
                    }
                }
            }
            .no-posts-container {
                flex-direction: column;
                flex: 1 1 100%;

                h2 {
                    color: #333333;
                    font-weight: bold;
                    padding: 0 1rem;
                    font-size: 2em;
                    line-height: 110%;
                    text-align: right;
                    letter-spacing: 0.1em;
                }

                p {
                    margin: 2rem 0;
                    font-size: 18px;
                    padding: 0 1rem;
                    line-height: 140%;
                    letter-spacing: 0.1em;
                }
            }
        }
    }
    .pagination-container {
        .pagination {
            .item {
                flex: unset;
                margin: 0 1rem;

                .indicators {
                    height: 60px;
                    width: 60px;

                    img {
                        height: 22px;
                        width: 27px;
                        margin-bottom: unset;
                    }
                }
                .indicators:hover {
                    background-color: #d2d2d2;
                    border: 1px solid #d2d2d2;
                }
                button,
                p {
                    line-height: 30px;
                    vertical-align: middle;
                }

                button {
                    background-color: #d2d2d2;
                    border: 1px solid #d2d2d2;
                    color: $text-dark;
                }
            }

            .item.active {
                p {
                    background-color: #b7cef8;
                    border: 1px solid #b7cef8;
                    color: white;
                }
            }
        }
    }
}

// Phone
@media only screen and (min-device-width: $phone) and (max-device-width: $desktop) {
    .blogList-container {
        .react-blogs-container {
            .filters {
                justify-content: center;

                .filterSelect-form {
                    width: 100%;
                    flex: 1 1 100%;
                    padding: 0;
                    justify-content: center;

                    .field-group {
                        flex: 0 1 80%;
                        margin: 1rem 0;
                    }
                    button {
                        flex: 0 1 80%;
                    }
                }
            }
            .posts-container {
                .container-col {
                    flex-basis: 100%;
                    max-height: unset;
                    min-height: unset;

                    .blog-card {
                        min-height: unset;

                        .img {
                            align-items: start;
                            h4 {
                                bottom: 25%;
                            }
                            h5 {
                                bottom: 20%;
                            }
                            img {
                                height: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}

//Tablet
@media only screen and (min-device-width: $tablet) and (max-device-width: $desktop) {
    .blogList-container {
        .react-blogs-container {
            .filters {
                flex-direction: row;
                justify-content: flex-end;

                .filterSelect-form {
                    width: unset;
                    flex: 0 1 60%;
                    padding: 0 8%;
                    justify-content: flex-end;

                    .field-group {
                        flex: 0 1 50%;
                        margin: 0 1rem;
                    }
                    button {
                        flex: 0 1 40%;
                    }
                }
            }
            .posts-container {
                justify-content: center;

                .container-col {
                    flex-basis: 25%;
                    margin: 1rem;
                    max-height: 400px;
                    min-height: 310px;

                    .blog-card {
                        min-height: 310px;
                        justify-content: flex-start;
                        .img {
                            h4 {
                                bottom: 15%;
                                font-size: 20px;
                            }
                            h5 {
                                bottom: 5%;
                                font-size: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}

//Large Screen
@media only screen and (min-device-width: 2560px) {
    .header-container .header {
        background-position-y: -170px;
    }
}
