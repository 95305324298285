@import "../../App.scss";

.blogLatest-container {
    flex: 1 1 100%;
    margin-top: 50px;
    flex-direction: column;

    .heading-wrapper {
        width: 100%;
        margin: 1rem 0 2rem 0;

        .heading-container {
            transform: translateX(20rem);
            max-width: 375px;

            hr {
                transform: translateX(130px);
                border-radius: 4px;
                width: 20%;
                background-color: $text-secondary;
                border: 3px solid $text-secondary;
            }
            h1 {
                margin-top: 1rem;
                color: $text-heading;
                z-index: 100;
                font-weight: bold;
                font-size: 44px;
                line-height: 110%;
                text-align: right;
                letter-spacing: 0.1em;
            }
            img {
                position: absolute;
                z-index: -10;
                bottom: -5%;
                left: 0;
            }
        }
    }
    .container-row {
        width: 80%;

        .card {
            margin: 1rem;
            font-family: Raleway;
            flex-basis: 28%;
            max-width: 33%;

            .blog-link-wrapper {
                width: 100%;
            }
            .img {
                width: 100%;
                height: 270px;
                min-height: unset;
                max-height: unset;
                img {
                    height: 270px;
                    width: 100%;
                }
                h5 {
                    font-size: 14px;
                    line-height: 110%;
                    letter-spacing: 0.1em;
                    position: absolute;
                    bottom: 5%;
                    left: 5%;
                    z-index: 1;
                    color: $text-light;
                }
                h4 {
                    font-weight: 900;
                    letter-spacing: 0;
                    line-height: 19px;
                    position: absolute;
                    bottom: 15%;
                    left: 5%;
                    font-size: 24px;
                    z-index: 1;
                    color: $text-light;
                }
            }
            .img:after {
                position: absolute;
                height: 100%;
                width: 100%;
                background-color: rgba(0, 0, 0, 0.2);
                top: 0;
                left: 0;
                display: block;
                content: "";
            }
        }
        .more-blog-card {
            .img {
                a {
                    z-index: 5;
                    position: absolute;
                    bottom: 20%;
                    button {
                        img {
                            height: unset;
                        }
                    }
                }
            }
        }
    }
}

// Phone
@media only screen and (min-device-width: $phone) and (max-device-width: $desktop) {
    .blogLatest-container {
        display: flex;
        flex-wrap: wrap;

        .heading-wrapper {
            margin: 1rem 0 2rem 0;

            .heading-container {
                transform: translateX(0);
                max-width: unset;
                width: 90%;
                margin-bottom: 0;
                padding: 10px 0;

                h1 {
                    font-size: 38px;
                }
            }
        }
        .container-row {
            padding: 0 3%;
            width: 95%;

            .card {
                flex-basis: 100%;
                max-width: unset;
                .img {
                    a {
                        display: flex;
                        flex-direction: column;
                    }
                    h4 {
                        bottom: 15%;
                    }
                }
            }
        }
    }
}

//Tablet
@media only screen and (min-device-width: $tablet) and (max-device-width: $desktop) {
    .blogLatest-container {
        .heading-wrapper {
            .heading-container {
                transform: translateX(5rem);
                height: 100px;
                max-width: 375px;

                h1 {
                    margin-top: 1rem;
                    font-size: 44px;
                    text-align: right;
                }
            }
        }
        .container-row {
            align-items: center;
            flex-direction: row;
            margin: 10px 0;
            flex-direction: row;
            flex: 1 1;
            flex-basis: 100%;
            padding: 0 1rem;
            margin: 20px 0;

            .card {
                max-width: 340px;
                text-align: left;
                margin: 1rem;
                flex-basis: 25%;
                padding: 10px;
                border-radius: 7px;
            }
        }
    }
    .blogLatest-container .container-row .card .img h5 {
        bottom: 1%;
    }
    .blogLatest-container .more-blog-card .img a {
        bottom: 10%;
    }
}

//Tablet
@media only screen and (min-device-width: $tablet) and (max-device-width: $desktop) and (orientation: landscape) {
    .blogLatest-container .container-row .card .img h4 {
        bottom: 20%;
    }
}
