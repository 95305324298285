@import "../../App.scss";

.navbar {
    width: 100%;
    background-color: white;

    .navbar-mobile {
        display: none;
    }

    .navbar-desktop {
        margin: 15px 5px;
        width: 100%;

        .navbar-main {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            width: 100%;
            justify-content: space-evenly;

            .navbar-logo {
                img {
                    width: 120px;
                }
            }
            .socials-container {
                display: flex;
                img {
                    height: 15px;
                    width: 15px;
                    margin: 10px;
                    padding: 3px;
                    border-radius: 3px;
                    border: 3px solid $text-secondary;
                    cursor: pointer;

                    &:hover {
                        background-color: $select-background;
                    }
                }
            }

            .join-us {
                padding: 0.5rem 3.5rem;
                cursor: pointer;
                border: 1px solid $text-primary;

                &:hover {
                    background-color: $select-background;
                }
            }
            ul {
                list-style-type: none;
                display: flex;
                width: 100%;
                justify-content: space-evenly;
                align-items: center;

                li {
                    padding: 10px 10px;
                }
                a {
                    text-decoration: none;
                    color: $text-primary;
                    font-family: Helvetica;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 150%;
                    letter-spacing: 0;

                    &:hover {
                        color: $text-dark;
                        cursor: pointer;
                    }
                }
                a.active {
                    display: flex;
                    background-color: $text-light;
                    cursor: pointer;
                    border-bottom: 3px solid $text-secondary;

                    &:hover {
                        color: $text-dark;
                    }
                    p {
                        color: $text-secondary;
                        font-weight: 900;
                        letter-spacing: 0;
                        line-height: 19px;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}
// Phone
@media only screen and (min-device-width: 0px) and (max-device-width: 1024px) {
    .navbar-desktop {
        display: none;
    }
    .navbar {
        .navbar-mobile {
            .nav {
                width: 100%;
            }
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            background-color: white;
            padding: 20px 0;
            align-content: center;
            justify-content: center;
        
            .navbar-header {
                display: flex;
                flex-direction: row;
                flex: 1 1 auto;
                padding: 1rem;
                justify-content: space-between;
            
                .mobile-logo {
                    img {
                        width: 120px;
                    }
                }
            }

        }
    }
}

