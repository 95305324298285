@import "../../App.scss";
.jobDetails-container {
    .col-table-heading {
        padding: 25px 100px;
        transform: translateY(35px);
        z-index: 100;
        background-color: $text-secondary;
        font-family: Helvetica;
        font-weight: bold;
        font-size: 18px;
        line-height: 150%;
        color: $text-heading;
    }
}
// Phone
@media only screen and (min-device-width: $phone) and (max-device-width: $desktop) {
    .jobDetails-container {
        .col-table-heading {
            padding: 15px 50px;
            z-index: 100;
            background-color: $text-secondary;
            font-family: Helvetica;
            font-weight: bold;
            font-size: 18px;
            line-height: 150%;
            color: $text-heading;
        }
    }
}
