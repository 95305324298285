@import "./settings.scss";

.btn-main {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 25px;
    color: $text-light;
    font-size: 15px;
    font-weight: bold;
    background-color: $button-main;
    cursor: pointer;
    border: 1px solid $button-text;

    img {
        margin-left: 2rem;
        width: 20px !important;
    }

    //Tablet
    @media only screen and (min-device-width: $tablet) and (max-device-width: $desktop) {
        img {
            margin-left: 0.5rem;
            width: 15px !important;
        }
    }
    &:hover {
        color: gray;
    }
}
.btn-subscribe {
    padding: 20px 90px;
    background-color: $button-main;
    cursor: pointer;
    border: 2px solid $button-text;

    &:hover {
        color: gray;
    }
}

.btn-secondary {
    font-size: 15px;
    font-weight: bold;
    padding: 15px 60px;
    background: white;
    border: 1px solid $text-primary;
    box-sizing: border-box;
    box-shadow: 0px 8px 20px rgba(64, 45, 27, 0.1);

    &:hover {
        color: $select-text;
    }
}

.select-main {
    padding: 20px 90px;
    background-color: $select-background;
    color: $select-text;
    font-family: Helvetica;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 110%;
    cursor: pointer;
    border: 1px solid $button-text;
    box-sizing: border-box;
    box-shadow: 0px 8px 20px rgba(64, 45, 27, 0.1);
    -webkit-appearance: none;

    &:hover {
        background-color: white;
    }
}
.text-main {
    padding: 20px 90px;
    background-color: $select-background;
    color: $select-text;
    font-family: Helvetica;
    font-size: 16px;
    line-height: 110%;
    cursor: auto;
    border: 1px solid $button-text;
    box-sizing: border-box;
    box-shadow: 0px 8px 20px rgba(64, 45, 27, 0.1);

    &:hover {
        background-color: white;
    }
}
