@import "../../App.scss";
.featuredJobs-container {
    flex: 1 1 100%;
    margin-top: 2rem;
    flex-direction: column;
    padding: 0 5%;

    .heading-wrapper {
        width: 100%;
        margin: 1rem 0 5rem 0;

        .heading-container {
            transform: translateX(10rem);
            max-width: 350px;

            hr {
                transform: translateX(130px);
                z-index: 100;
                border-radius: 4px;
                width: 20%;
                background-color: $text-secondary;
                border: 3px solid $text-secondary;
            }
            h1 {
                color: $text-heading;
                font-weight: bold;
                font-size: 48px;
                line-height: 110%;
                text-align: right;
                letter-spacing: 0.1em;
            }
            img {
                position: absolute;
                bottom: 0;
                left: 20%;
                top: 60%;
                z-index: -10;
            }
        }
    }
    .featuredJobs-row {
        flex: 1 1 100%;
        align-items: stretch;

        .column {
            background: $select-background;
            border: 1px solid $table-border;
            box-sizing: border-box;
            margin: 0 10px;
        }
        .container-col-table {
            flex-basis: 70%;
            flex-direction: column;
            padding-top: 2rem;

            .heading {
                padding: 20px 50px;
                position: absolute;
                top: -50px;
                z-index: 100;
                background-color: $text-secondary;
                font-family: Helvetica;
                font-weight: bold;
                font-size: 18px;
                line-height: 150%;
                color: $text-heading;
            }
            table {
                display: flex;
                flex-direction: column;
                width: 100%;

                .table-row {
                    justify-content: space-around;
                    padding: 20px;

                    h3 {
                        margin: 5px 0;
                    }
                }
                .table-col {
                    width: 33%;
                    flex-direction: column;
                    display: flex;
                    a {
                        max-width: 220px;
                    }
                }
                .breakLine {
                    width: 100%;
                    margin-top: 20px;
                    border: 1px solid $table-border;
                }
            }
        }
        .container-col-card {
            flex-basis: 25%;
            justify-content: flex-start;
            flex-direction: column;
            font-family: Helvetica;
            line-height: 150%;
            font-size: 18px;

            .heading {
                position: absolute;
                z-index: 100;
                top: -50px;
                padding: 20px 30px;
                background-color: $button-main;
                font-family: Helvetica;
                font-weight: bold;
                font-size: 18px;
                line-height: 150%;
                color: $text-light;
            }
            .job-image {
                width: 100%;
                img {
                    max-height: 350px;
                    width: 100%;
                }
            }
            .card-wrapper {
                padding: 0 20px;
                text-align: left;
                justify-content: flex-start;

                .card-header {
                    width: 100%;
                    align-items: flex-start;
                    align-content: flex-start;
                    flex-direction: column;
                    justify-content: flex-start;
                    margin: 1rem 1rem 0rem 0;

                    h2 {
                        width: 80%;
                        color: $text-heading;
                        font-weight: bold;
                        font-size: 24px;
                    }
                    h5 {
                        font-size: 18px;
                        margin-top: 15px;
                        color: $select-text;
                    }
                    img {
                        position: absolute;
                        right: 0;
                        top: 0;
                    }
                }
                .card-info {
                    width: 100%;
                    justify-content: space-between;
                    margin: 1rem 1rem 1rem 0;
                    font-weight: normal;
                    color: $select-text;

                    strong {
                        color: $text-heading;
                        font-weight: bold;
                    }
                }

                .card-main {
                    margin: 10px;
                    p {
                        font-size: 14px;
                        line-height: 140%;
                        letter-spacing: 0.1em;
                        margin-bottom: 10px;
                    }
                    button {
                        padding: 15px 50px;
                    }
                }
            }
        }
    }
}
// Phone
@media only screen and (min-device-width: $phone) and (max-device-width: $desktop) {
    .featuredJobs-container {
        display: flex;
        flex-wrap: wrap;
        padding: 0 3%;

        .heading-wrapper {
            width: 90%;
            margin: 1rem 0 4rem 0;

            .heading-container {
                transform: translateX(0);
                max-width: unset;

                h1 {
                    font-size: 38px;
                    line-height: 100%;
                }

                img {
                    left: 25%;
                    top: 55%;
                }
            }
        }
        .featuredJobs-row {
            flex-direction: column-reverse;

            .column {
                width: 100%;
                margin: unset;
                min-height: unset;
            }

            .container-col-table {
                flex-basis: unset;
                padding-top: 30px;

                .heading {
                    padding: 10px 20px;
                    top: -30px;
                }
                table {
                    .table-row {
                        width: 100%;
                        flex-direction: column;
                        justify-content: space-around;
                        padding: unset;
                    }
                    .table-col {
                        margin: 5px 0;
                        width: 70%;
                        align-items: center;

                        td {
                            margin-bottom: 0.5rem;
                            text-align: center;
                        }
                        h3 {
                            text-align: center;
                        }
                        button {
                            width: 200px;
                            padding: 10px 30px;
                            background: white;
                            border: 1px solid $text-primary;
                            box-sizing: border-box;
                            box-shadow: 0px 8px 20px rgba(64, 45, 27, 0.1);
                        }
                    }
                }
                .breakLine {
                    width: 95%;
                    border: 1px solid $table-border;
                }
            }
            .container-col-card {
                .heading {
                    position: absolute;
                    top: -20px;
                    padding: 10px 20px;
                }
                .job-image {
                    width: unset;
                }
                margin-bottom: 50px;
                img {
                    max-width: 100%;
                }

                .card-wrapper {
                    padding: 10px;
                    text-align: center;
                    justify-content: center;

                    .card-header {
                        justify-content: center;
                        flex-direction: column;
                        margin: 10px;

                        h2 {
                            width: 80%;
                            text-align: left;
                        }
                        img {
                            position: absolute;
                            right: 5%;
                            top: 10px;
                        }
                    }
                    .card-info {
                        width: 100%;
                        margin: 0 1rem 1rem 10px;
                    }
                    .card-main {
                        button {
                            padding: 10px 30px;
                        }
                    }
                }
            }
        }
    }
}
// XS Phone
@media only screen and (max-device-width: 320px) {
    .featuredJobs-container {
        padding: 0;
    }
}

//Tablet
@media only screen and (min-device-width: $tablet) and (max-device-width: $desktop) {
    .featuredJobs-container {
        padding: 0 1%;
        .heading-wrapper .heading-container {
            max-width: 350px;
        }

        .featuredJobs-row {
            flex-direction: row;
            justify-content: space-evenly;
            align-items: flex-start;
            .container-col-table {
                width: unset;
                padding-top: unset;
                flex-basis: 65%;
                padding: 0 10px;

                .heading {
                    top: -25px;
                }
                table {
                    .table-row {
                        align-items: center;
                        flex-direction: row;
                        padding: 20px 0;
                    }
                    .table-col {
                        button {
                            width: unset;
                            padding: 10px 25px;
                        }
                    }
                }
            }
            .container-col-card {
                flex-basis: 32%;
                width: unset;
                margin-bottom: 0;

                .job-image {
                    img {
                        width: 100%;
                    }
                }
                .card-wrapper {
                    .card-header {
                        h2 {
                            text-align: left;
                            width: 100%;
                        }
                        img {
                            right: 0;
                            top: 0;
                            width: 50px;
                            height: 50px;
                        }
                    }
                    .card-main {
                        button {
                            padding: 10px 30px;
                            width: unset;

                            img {
                                height: unset;
                            }
                        }
                    }
                }
                img {
                    width: 45%;
                    height: 338px;
                    object-fit: cover;
                    max-width: unset;
                }
            }
        }
    }
}
//Tablet
@media only screen and (min-device-width: $tablet) and (max-device-width: $desktop) and (orientation: landscape) {
    .featuredJobs-container {
        margin-top: 10%;
    }
}
