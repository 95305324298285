@import "../../App.scss";

// Phone
@media only screen and (min-device-width: $phone) and (max-device-width: $desktop) {
.sideDrawer {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 100%;
    background-color: white;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 200;
    overflow-y: auto;
    transition: transform 0.3s ease-out;
    transform: translateX(-100%);
}
.sideDrawer.open {
    transform: translateX(0);
}

.close-button-container {
    display: flex;
    flex: 1 1 auto;
    width: 100%;
    justify-content: flex-end;
    margin: 1rem 0 0 0;

    button {
        flex: 0 1 auto;
        padding-right: 2rem;
    }
}

.menu-container {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
    flex-direction: column;
    list-style-type: none;

    .menu-heading {
        margin: 20px 0;
        h1 {
            color: $text-secondary;
            font-size: 25px;
            font-weight: 900;
            letter-spacing: 0;
            line-height: 30px;
            text-align: center;
        }

        img {
            width: 80%;
        }
    }
    .menu-item {
        margin-bottom: 1rem;
        a {
            color: $text-heading;
            font-size: 20px;
            font-weight: 900;
            letter-spacing: 0;
            line-height: 30px;
        }
    }
}

.phone-container {
    display: flex;
    flex: 1 1 auto;
    margin: 1rem;
    font-size: 20px;
    font-weight: 600;
    color: $text-heading;

    img {
        width: 30px;
        height: 40px;
    }
    p {
        margin-top: 0.5rem;
        margin-left: 0.5rem;
    }
}

.buttons-container {
    display: flex;
    flex-direction: column;
    margin-top: auto;

    .join-us-side {
        padding: 0.5rem 3.5rem;
        cursor: pointer;
        border: 1px solid $text-primary;

        &:hover {
            background-color: $select-background;
        }
    }
}
.socials-container {
    width: 100%;
    flex: 1 1 auto;
    img {
        height: 15px;
        width: 15px;
        margin: 10px;
        padding: 3px;
        border-radius: 3px;
        border: 3px solid $text-secondary;
        cursor: pointer;

        &:hover {
            background-color: $select-background;
        }
    }
}
}
