@import '../../App.scss';

$height: 3rem;

.ListOptions {
    display: flex;
    flex: 0 1 auto;
    transition: width 200ms ease;
    position: relative;

    .ListOptions-nav {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      height: auto;
      width: 100%;
    }
      
    .nav-item {
        width: 100%;
        z-index: 1;
    }
      
    .nav-item:last-child {
        margin-top: auto;
    }

    .nav-item.show {
      z-index: 1000;
      
      .nav-link.dropdown {
        filter: grayscale(0%) opacity(1);
        color: $text-dark;
      }
    }
      
    .nav-link {
      display: flex;
      align-items: center;
      justify-content: center;
      height: $height;
      color: var(--text-primary);
      text-decoration: none;
      filter: grayscale(100%) opacity(0.7);
      transition: var(--transition-speed);
    }

    .nav-link.dropdown {

      .dropdown-menu {
        display: none;
        position: absolute;
        top: $height;
        right: 0;
        background-color: white;
        // width: 100%;
        // flex: 1 1 100%;
        -webkit-box-shadow: 0 3px 6px -4px rgba(0,0,0,.12), 0 6px 16px 0 rgba(0,0,0,.08), 0 9px 28px 8px rgba(0,0,0,.05);
        box-shadow: 0 3px 6px -4px rgba(0,0,0,.12), 0 6px 16px 0 rgba(0,0,0,.08), 0 9px 28px 8px rgba(0,0,0,.05);
        z-index: 1;

        .dropdown-link {
          flex: 1 1 100%;
          display: inline-flex;
          align-items: center;
          height: $height;
          color: $text-dark;
          text-decoration: none;
          filter: grayscale(100%) opacity(0.7);
          transition: var(--transition-speed);

          .link-text {
            display: inline;
            margin-left: 1rem;
            font-size: 0.75rem;
          }

          svg {
            min-width: 2rem;
            margin: 0 0.5rem;
            height: 1.5rem;
          }

          &:hover {
            filter: grayscale(0%) opacity(1);
            color: $text-dark;
          }
        }
      }

      .dropdown-menu.show {
        display: flex;
        z-index: 1000;
      }

      &::after {
        display: inline-block;
        margin-left: .255em;
        margin-right: .255em;
        vertical-align: .255em;
        content: "";
        border-top: .3em solid;
        border-right: .3em solid transparent;
        border-bottom: 0;
        border-left: .3em solid transparent;
      }
    }
      
    .nav-link:hover {
        filter: grayscale(0%) opacity(1);
        color: var(--text-secondary);
    }
      
    .link-text {
        display: inline;
        margin: 0 1rem;
    }
      
    .nav-link svg {
        min-width: 2rem;
        margin: 0 0.25rem;
        height: 1.5rem;
    }
}

/* Small screens */
@media only screen and (max-width: 600px) {
    .ListOptions {
      bottom: 0;
      // width: 100vw;
      height: $height;

      .logo {
        display: none;
      }
    
      .ListOptions-nav {
        flex-direction: row;
      }
    
      .nav-link {
        justify-content: center;
      }
    }
}
  
/* Large screens */
@media only screen and (min-width: 600px) {
    .ListOptions {
      top: 0;
      width: 100%;
      height: $height;

      &:hover {

        .link-text {
          display: inline;
        }
      }
    } 
}
