@import "../../App.scss";

.admin-container {
    flex: 1 1 100%;

    main {
        /* POSTS LIST */
        .PostList-Admin {
            padding: 0 10%;
            h1 {
                margin: 1rem 0;
            }
            .card {
                background: $select-background;
                border: 1px solid $table-border;
                box-sizing: border-box;
                margin: 1rem;
                
                .card-body {
                    button {
                        font-family: Helvetica !important;
                    }:hover {
                        color: $text-secondary;
                    }
                }
            }
        }

        /* POSTS LIST */

        /* EDIT POST */
        .container {
            justify-content: space-between;

            .btn-main {
                background-color: $text-secondary;
                font-size: 1.2rem;
                font-weight: 600;
                border-color: $text-secondary;
                border-radius: 5px;
                color: white;
                padding: 0.5rem 1rem;
                transition: background-color 0.5s ease-in-out, b;
            }
            .btn-main:hover {
                background-color: red;
                color: white;
                border-color: red;
            }
        }
        .blogEditor {
            .container-row {
                margin-top: unset;
            }
        }
        /* EDIT POST */
    }
}
